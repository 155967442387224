import TrackingHistoryEvent from '@components/TrackingHistory/TrackingHistoryEvent';
import { TrackingContext } from '@context/TrackingContext';
import React from 'react';
import './TrackingHistoryEventList.css';

const TrackingHistoryEventList = () => {
  const trackingResponse = React.useContext(TrackingContext)?.trackingResponse;
  if (!(trackingResponse && trackingResponse.shipment)) {
    return null;
  }
  const { trackingEvents } = trackingResponse.shipment;

  return (
    <div className="tracking-events-wrapper">
      {trackingEvents.map((trackingEvent) => (
        // TODO: Set the key property
        <TrackingHistoryEvent trackingEvent={trackingEvent} />
      ))}
    </div>
  );
};

export default TrackingHistoryEventList;
