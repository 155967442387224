import { TrackingContext } from '@context/TrackingContext';
import Typography from '@typography/Typography';
import React from 'react';
import getShipmentSummary from './helpers/getShipmentSummary';
import './Summary.css';

const Summary = () => {
  const trackingResponse = React.useContext(TrackingContext)?.trackingResponse;
  if (!trackingResponse) {
    return null;
  }

  return (
    <Typography className="shipment-summary" fontFamily="centra-book">
      {getShipmentSummary(trackingResponse)}
    </Typography>
  );
};

export default Summary;
