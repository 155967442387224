import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import CATEGORIES from '@shared/components/NavBar/constants/navCategories';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import Typography from '@typography/Typography';
import React from 'react';

const NavBar = () => (
  <div className="navigation-bar">
    <div className="navigation-container">
      <ul className="navigation-list">
        {CATEGORIES.map((CATEGORY) => (
          <li className="navigation-item" key={CATEGORY.name}>
            <Hyperlink
              href={CATEGORY.link}
              target={ANCHOR_TARGET}
              className={`navigation-link ${
                CATEGORY.redAccent ? 'red-accent' : ''
              }`}
            >
              <Typography
                component="span"
                fontFamily="centra-medium"
                className="navigation-text"
                align="center"
              >
                {CATEGORY.name}
              </Typography>
            </Hyperlink>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default NavBar;
