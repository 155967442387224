import { ButtonText } from '@button/ButtonText';
import IconApp from '@icon/components/IconApp';
import footerData from '@shared/components/Footer/mockData/footer.mockData';
import Col from '@shared/components/Footer/layout/Col';
import { survey } from '@shared/components/Footer/utilities/medallia';
import HyperlinkIconText from '@shared/components/Hyperlink/HyperlinkIconText';
import React from 'react';

const FooterApp = () => (
  <Col className="footer-app">
    <div className="footer-app-wrapper">
      <ButtonText
        className="font-centra-book uppercase text-gray-dark"
        label={footerData.footerFeedback.label}
        onClick={() => survey(footerData.footerFeedback.formId)}
      />
      <span className="px-3">|</span>
      <HyperlinkIconText
        icon={<IconApp />}
        width={20}
        height={20}
        target="_blank"
        label={footerData.footerApp.label}
        href={footerData.footerApp.href}
        reverse={true}
      />
    </div>
  </Col>
);

export default FooterApp;
