import IconChevron from '@icon/components/IconChevron';
import IconChevronLeft from '@icon/components/IconChevronLeft';
import IconChevronRight from '@icon/components/IconChevronRight';
import IconCircle from '@icon/components/IconCircle';
import IconClose from '@icon/components/IconClose';
import IconDot from '@icon/components/IconDot';
import IconFacebook from '@icon/components/IconFacebook';
import IconHeart from '@icon/components/IconHeart';
import IconInstagram from '@icon/components/IconInstagram';
import IconMinus from '@icon/components/IconMinus';
import IconPinterest from '@icon/components/IconPinterest';
import IconPlus from '@icon/components/IconPlus';
import IconProfile from '@icon/components/IconProfile';
import IconSO5 from '@icon/components/IconSO5';
import IconTwitter from '@icon/components/IconTwitter';

const templateOptions = [
  { name: 'chevron-left', component: IconChevronLeft },
  { name: 'chevron-right', component: IconChevronRight },
  { name: 'chevron', component: IconChevron },
  { name: 'circle', component: IconCircle },
  { name: 'close', component: IconClose },
  { name: 'dot', component: IconDot },
  { name: 'so5', component: IconSO5 },
  { name: 'heart', component: IconHeart },
  { name: 'profile', component: IconProfile },
  { name: 'plus', component: IconPlus },
  { name: 'minus', component: IconMinus },
  { name: 'facebook', component: IconFacebook },
  { name: 'instagram', component: IconInstagram },
  { name: 'pinterest', component: IconPinterest },
  { name: 'twitter', component: IconTwitter },
];

export default templateOptions;
