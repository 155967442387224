export const getQueryString = (param: string): string | null => {
  const searchParams = new URLSearchParams(window.location.search);
  let paramValue = searchParams.get(param);
  if (paramValue) {
    paramValue = paramValue.toUpperCase();
  }
  return paramValue;
};

export default {
  getQueryString,
};
