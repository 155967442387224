import Skeleton from '@components/OrderNumber/Skeleton';
import ORDER_STATUS_LINK from '@components/OrderNumber/constants/orderStatusLink';
import { OrderContext } from '@context/OrderContext';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import Typography from '@typography/Typography';
import { getDateToString } from '@utils/formatting';
import { getHtmlCodeSymbolText } from '@utils/htmlCodePaser';
import React from 'react';
import './OrderNumber.css';

const OrderNumber = () => {
  const orderResponse = React.useContext(OrderContext)?.orderResponse;
  const isOrderLoading = React.useContext(OrderContext)?.isOrderLoading;

  if (isOrderLoading) {
    return <Skeleton />;
  }
  if (!orderResponse) {
    return null;
  }
  const orderNumberSymbolText = getHtmlCodeSymbolText('HASHTAG_SIGN');
  const pipeSymbolText = getHtmlCodeSymbolText('PIPE_SIGN');
  const { orderNumber, orderDate } = orderResponse;
  const orderDateString = getDateToString(orderDate, 'MMM DD, yyyy');
  return (
    <div className="order-number-container">
      <Typography
        component="h4"
        fontFamily="centra-book"
        className="order-number-text"
      >
        Order {orderNumberSymbolText}
        <a
          href={ORDER_STATUS_LINK}
          target={ANCHOR_TARGET}
          rel="noreferrer"
          className="order-number-link"
        >
          {orderNumber}
        </a>
        {` ${pipeSymbolText} ${orderDateString}`}
      </Typography>
    </div>
  );
};

export default OrderNumber;
