import { STAGING_URLS, PRODUCTION_URLS } from '@src/constants/environmentUrls';
import { STAGING_PROXY_BASE_URL, PRODUCTION_PROXY_BASE_URL } from './constants';
import type { ITrackingEvent } from '@_types/type';

const PREFIX_MOCK = 'MOCK_';

export const isMock = (trackNumber: string | undefined) => {
  if (!trackNumber) return false;
  return trackNumber.includes(PREFIX_MOCK);
};

export const fetchResponse = <T>(
  url: string,
  props: RequestInit | undefined = undefined,
): Promise<T> =>
  new Promise((resolve, reject) => {
    fetch(url, props)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Request failed');
        }
        return response.json();
      })
      .then((data: T) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getProxyServerUrl = () => {
  const currentLocationURL = window.location.href;

  if (STAGING_URLS.some((url: string) => currentLocationURL.includes(url))) {
    return STAGING_PROXY_BASE_URL;
  }
  if (PRODUCTION_URLS.some((url: string) => currentLocationURL.includes(url))) {
    return PRODUCTION_PROXY_BASE_URL;
  }
  return '';
};

export const sortEventDatesDesc = (events: ITrackingEvent[]) => {
  const sortedEvents = events.sort((a, b) => {
    const dateA = new Date(a.eventTimestamp).getTime();
    const dateB = new Date(b.eventTimestamp).getTime();
    return dateB - dateA;
  });
  return sortedEvents;
};

export default { isMock, fetchResponse };
