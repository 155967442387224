import TrackingCarrierLogoMap from '@src/constants/trackingCarrierLogoMap';
import Typography from '@typography/Typography';
import React from 'react';
import type { ITrackingHistoryCarrierLogoProps } from '@components/TrackingHistory/ITrackingHistory';
import './TrackingHistoryCarrierLogo.css';

const TrackingHistoryCarrierLogo = (
  props: ITrackingHistoryCarrierLogoProps,
) => {
  const { carrier } = props;
  return TrackingCarrierLogoMap[carrier] ? (
    <img src={TrackingCarrierLogoMap[carrier]} alt={carrier} />
  ) : (
    <Typography fontFamily="centra-bold" className="carrier-name">
      {carrier.toLowerCase()}
    </Typography>
  );
};

export default TrackingHistoryCarrierLogo;
