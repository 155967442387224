import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Profile: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4085 17.6667C10.8773 17.6667 8.8252 15.6146 8.8252 13.0833V11.8333C8.8252 9.30208 10.8773 7.25 13.4085 7.25C15.9398 7.25 17.9919 9.30208 17.9919 11.8333V13.0833C17.9919 15.6146 15.9398 17.6667 13.4085 17.6667Z"
      stroke="#212427"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.12891 23.6629C7.14724 21.592 9.27849 20.1666 11.7418 20.1666H15.0752C17.536 20.1666 19.6685 21.5958 20.6881 23.6629"
      stroke="#212427"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4087 26C20.3123 26 25.9087 20.4036 25.9087 13.5C25.9087 6.59644 20.3123 1 13.4087 1C6.50513 1 0.908691 6.59644 0.908691 13.5C0.908691 20.4036 6.50513 26 13.4087 26Z"
      stroke="#212427"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default withIcon(Profile);
