import ShipmentStatus from '@components/enums';
import { getDateToString } from '@utils/formatting';
import type { ITrackingResponse, ITrackingEvent } from '@_types/type';

const IN_TRANSIT = 'In Transit';
const DELIVERY_EXCEPTION = 'Delivery Exception';
const TEMP_UNAVAILABLE = 'Temporarily Unavailable';
const AWAITING_CARRIER_PICKUP = 'Awaiting Carrier Pickup';

const getDateFromEventByStatus = (
  status: string,
  trackingEvents: ITrackingEvent[],
): string | null => {
  for (let i = trackingEvents.length - 1; i >= 0; i--) {
    if (trackingEvents[i].normalizedShipmentStatus === status) {
      return trackingEvents[i].eventTimestamp;
    }
  }
  return null;
};

const getDeliverySummary = (trackingResponse: ITrackingResponse): string => {
  const { shipment } = trackingResponse;
  if (!shipment) {
    return TEMP_UNAVAILABLE;
  }

  const shipmentStatus = shipment?.shipmentStatus;
  const estimatedDeliveryDate = shipment?.estimatedDeliveryDate;
  const date =
    estimatedDeliveryDate ||
    getDateFromEventByStatus(shipmentStatus, shipment.trackingEvents);

  switch (shipmentStatus) {
    case ShipmentStatus.Ordered:
      return AWAITING_CARRIER_PICKUP;
    case ShipmentStatus.DeliveryException:
      return DELIVERY_EXCEPTION;
    default:
      if (!date && shipmentStatus === ShipmentStatus.OutForDelivery) {
        return IN_TRANSIT;
      }
      if (date) {
        return getDateToString(date, 'dddd, MMM D');
      }
      return '';
  }
};

export default getDeliverySummary;
