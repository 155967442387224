export const getFillColor = (isCurrentStage: boolean): string =>
  isCurrentStage ? 'fill-petrol-dark-2' : 'fill-petrol-bright';
export const getStrokeColor = (isCurrentStage: boolean): string =>
  isCurrentStage ? 'stroke-petrol-dark-2' : 'stroke-petrol-bright';
export const getStyleByStage = (isCurrentStage: boolean) => ({
  fillColor: getFillColor(isCurrentStage),
  strokeColor: getStrokeColor(isCurrentStage),
});

export default {
  getFillColor,
  getStrokeColor,
  getStyleByStage,
};
