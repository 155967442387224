import React from 'react';

const ICON_NUMBER_THREE: React.ReactNode = (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99319 8.136C3.24919 8.136 3.48519 8.1 3.70119 8.028C3.92519 7.956 4.11719 7.856 4.27719 7.728C4.44519 7.592 4.57319 7.432 4.66119 7.248C4.75719 7.056 4.80519 6.848 4.80519 6.624C4.80519 6.408 4.75719 6.208 4.66119 6.024C4.57319 5.84 4.44919 5.684 4.28919 5.556C4.12919 5.428 3.94119 5.328 3.72519 5.256C3.50919 5.184 3.27719 5.148 3.02919 5.148H2.24919V4.188H3.02919C3.22119 4.188 3.40119 4.156 3.56919 4.092C3.74519 4.02 3.90119 3.924 4.03719 3.804C4.17319 3.684 4.28119 3.548 4.36119 3.396C4.44119 3.236 4.48119 3.072 4.48119 2.904C4.48119 2.72 4.44119 2.548 4.36119 2.388C4.28919 2.228 4.18519 2.092 4.04919 1.98C3.91319 1.86 3.75319 1.768 3.56919 1.704C3.38519 1.64 3.18519 1.608 2.96919 1.608C2.61719 1.608 2.27719 1.688 1.94919 1.848C1.62919 2 1.30119 2.22 0.965191 2.508V1.332C1.26919 1.1 1.58919 0.924 1.92519 0.804C2.26919 0.684 2.62119 0.624 2.98119 0.624C3.34919 0.624 3.68919 0.68 4.00119 0.792C4.31319 0.904 4.58119 1.056 4.80519 1.248C5.03719 1.44 5.21319 1.672 5.33319 1.944C5.46119 2.208 5.52519 2.496 5.52519 2.808C5.52519 3.2 5.42519 3.556 5.22519 3.876C5.02519 4.188 4.74119 4.432 4.37319 4.608C4.82919 4.76 5.19319 5.02 5.46519 5.388C5.73719 5.748 5.87319 6.164 5.87319 6.636C5.87319 7.004 5.80119 7.34 5.65719 7.644C5.51319 7.948 5.31319 8.212 5.05719 8.436C4.80119 8.652 4.49719 8.824 4.14519 8.952C3.79319 9.072 3.41319 9.132 3.00519 9.132C2.20519 9.132 1.45319 8.916 0.749191 8.484V7.332C1.47719 7.868 2.22519 8.136 2.99319 8.136Z"
      fill="#003E41"
    />
  </svg>
);

const ICON_NUMBER_FOUR: React.ReactNode = (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.552723 6.264L4.64472 0.767999H5.84472V6.132H6.99672V7.068H5.84472V9H4.80072V7.068H0.552723V6.264ZM4.80072 6.132V2.124L1.80072 6.132H4.80072Z"
      fill="#003E41"
    />
  </svg>
);

interface IIconNumbersProps {
  [key: number]: React.ReactNode;
}
const iconNumbers: IIconNumbersProps = {
  3: ICON_NUMBER_THREE,
  4: ICON_NUMBER_FOUR,
};

export default iconNumbers;
