const footerData = {
  main: [
    {
      id: 'support',
      header: 'Support',
      ariaControls: 'saks-first-card',
      links: [
        {
          label: 'Contact Us',
          external: true,
          href: 'https://support.saksoff5th.com/s/contactsupport',
        },
        {
          label: 'Help & FAQ',
          external: true,
          href: 'https://support.saksoff5th.com/s/',
        },
        {
          label: 'Shipping Policy',
          external: false,
          href: 'https://www.saksoff5th.com/content/shipping-policy',
        },
        {
          label: 'Returns & Exchanges',
          external: false,
          href: 'https://www.saksoff5th.com/c/hidden/content/returns-exchanges',
        },
        {
          label: 'Order Status & Tracking',
          external: false,
          href: 'https://www.saksoff5th.com/account/order-status',
        },
        {
          label: 'International Shipping',
          external: false,
          href: 'https://www.saksoff5th.com/content/international',
        },
      ],
    },
    {
      id: 'services',
      header: 'Services',
      ariaControls: 'saks-second-card',
      links: [
        {
          label: 'Gift Cards',
          external: false,
          href: 'https://www.saksoff5th.com/c/hidden/content/gift-card',
        },
        {
          label: 'OFF 5th Rewards',
          external: false,
          href: 'https://www.saksoff5th.com/loyalty-landing.html',
          isLoyalty: true,
        },
        {
          label: 'Buy Online, Pick Up in Store',
          external: false,
          href: 'https://www.saksoff5th.com/c/hidden/content/bopis',
        },
        {
          label: 'Klarna',
          external: false,
          href: 'https://www.saksoff5th.com/c/hidden/content/klarna?sre=footer',
        },
      ],
    },
    {
      id: 'saksfirst',
      header: 'SaksFirst Card',
      ariaControls: 'saks-thrid-card',
      links: [
        {
          label: 'About SaksFirst',
          external: false,
          href: 'https://www.saksoff5th.com/saks-first',
        },
        {
          label: 'Apply for the SaksFirst Store Card',
          external: false,
          href: 'https://www.saksoff5th.com/saks-first',
        },
        {
          label: 'Pay & Manage SaksFirst Store Card',
          external: true,
          href: 'https://saks.capitalone.com/#/sign-in',
        },
        {
          label: 'Pay & Manage SaksFirst Mastercard',
          external: true,
          href: 'https://saks.capitalone.com/#/sign-in',
        },
      ],
    },
    {
      id: 'stores',
      header: 'Stores & Corporate',
      ariaControls: 'saks-fourth-card',
      links: [
        {
          label: 'About Us',
          external: false,
          href: 'https://www.saksoff5th.com/aboutus.html',
        },
        {
          label: 'Diversity Equity & Inclusion',
          external: false,
          href: 'https://www.saksoff5th.com/c/diversity-equity-inclusion',
        },
        {
          label: 'Store Locations & Events',
          external: false,
          href: 'https://locations.saksoff5th.com/',
        },
        {
          label: 'Privacy Policy',
          external: false,
          href: 'https://www.saksoff5th.com/content/privacy-policy',
        },
        {
          label: 'Website Terms of Use',
          external: false,
          href: 'https://www.saksoff5th.com/terms-of-use.html',
        },
        {
          label: "careers-we're hiring",
          external: true,
          href: 'https://www.careersatsaksoff5th.com/',
          className: 'text-accent font-centra-bold uppercase',
          style: {
            color: '#00686c',
            fontWeight: 'bold',
          },
        },
        {
          label: 'Committed to Our Communities',
          external: false,
          href: 'https://www.saksoff5th.com/c/hidden/content/charity',
        },
      ],
    },
  ],
  footerSocial: [
    {
      label: 'Instagram',
      icon: 'instagram',
      iconOnly: true,
      href: 'https://www.instagram.com/saksoff5th/',
    },
    {
      label: 'Facebook',
      icon: 'facebook',
      iconOnly: true,
      href: 'https://www.facebook.com/saksoff5th',
    },
    {
      label: 'Pinterest',
      icon: 'pinterest',
      iconOnly: true,
      href: 'https://in.pinterest.com/saksoff5th/',
    },
    {
      label: 'Twitter',
      icon: 'twitter',
      iconOnly: true,
      href: 'https://twitter.com/saksoff5th',
    },
  ],
  footerLegal: {
    label: 'CA Transparency in Supply Chains Act',
    external: true,
    href: 'https://www.hbc.com/vendors/vendor-guides-and-policies/california-transparency-in-supply/',
  },
  footerApp: {
    label: 'Get The Saks OFF 5TH App',
    external: true,
    href: 'https://www.saksoff5th.com/c/hidden/content/off5th-app',
  },
  footerFeedback: {
    label: 'Site Feedback',
    formId: '16551',
  },
};

export default footerData;
