import { Disclosure } from '@headlessui/react';
import IconMinus from '@icon/components/IconMinus';
import IconPlus from '@icon/components/IconPlus';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import FooterContext from '@shared/context/FooterContext';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import footerData from '@shared/components/Footer/mockData/footer.mockData';
import screenBreakpoints from '@constants/screenBreakpoints';
import {
  addResizeListener,
  removeResizeListener,
} from '@shared/utilities/resizeListener';

const FooterColumns: React.FC = () => {
  const items = footerData.main;
  const { showLoyalty } = useContext(FooterContext);
  const [isDesktop, setIsDesktop] = useState(false);
  const { md } = screenBreakpoints;

  const handleResize = () => {
    setIsDesktop(window.innerWidth > md);
  };

  useEffect(() => {
    handleResize();
    addResizeListener(handleResize);
    return () => removeResizeListener(handleResize);
  }, []);

  return (
    <>
      {items.map((item, idx) => (
        <div
          key={item.id}
          className={cx(
            'last:border-b',
            'last:border-b-tin',
            'border-t',
            'border-t-tin',
            'lg:h-full',
            'lg:border-y',
            'lg:border-y-tin',
          )}
        >
          <Disclosure>
            {({ open }) => (
              <>
                <h2
                  className={cx(
                    'footer-column-title',
                    'relative',
                    'font-centra-medium',
                    'm-0',
                    'text-base',
                    'py-3',
                    'block',
                    'w-full',
                    'text-left',
                    'text-shadow',
                    'text-[15px]',
                    'lg:text-4',
                    'lg:pb-1.5',
                    'lg:text-dark-gray',
                  )}
                >
                  <Disclosure.Button
                    id={item.id}
                    className={cx(
                      'flex',
                      'justify-between',
                      'items-center',
                      'w-full',
                      'text-[15px]',
                      'lg:text-4',
                      'lg:pointer-events-none',
                    )}
                  >
                    <span className="float-left">{item.header}</span>

                    {open ? (
                      <IconMinus
                        width={14}
                        height={14}
                        className={cx(
                          'text-sm',
                          'text-gray-500',
                          'lg:invisible',
                        )}
                      />
                    ) : (
                      <IconPlus
                        width={14}
                        height={14}
                        className={cx(
                          'text-sm',
                          'text-gray-500',
                          'lg:invisible',
                        )}
                      />
                    )}
                  </Disclosure.Button>
                </h2>
                <Disclosure.Panel static={isDesktop} id={`${item.id}-panel`}>
                  <ul className="last:mb-4 lg:animate-none">
                    {item.links.map(
                      (
                        { className, href, isLoyalty, label, style }: any,
                        linkIdx,
                      ) => (
                        <li key={linkIdx} className="block">
                          <Hyperlink
                            href={href}
                            target="_blank"
                            className={cx(
                              className,
                              'block',
                              'py-1',
                              'hover:border-b-0',
                              {
                                hidden: !showLoyalty && isLoyalty,
                              },
                            )}
                            label={label}
                            style={style}
                          />
                        </li>
                      ),
                    )}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ))}
    </>
  );
};

export default FooterColumns;
