import FEDEXLOGO from '@assets/images/FedEx-Logo.png';
import TFORCEFREIGHTLOGO from '@assets/images/TForce_Freight-logo.png';
import AIRTERRALOGO from '@assets/images/airterra-logo.png';
import CDLLOGO from '@assets/images/cdl-logo.png';
import DHLLOGO from '@assets/images/dhl-logo.png';
import ONTRACLOGO from '@assets/images/ontrac-logo.png';
import PANDIONLOGO from '@assets/images/pandion-logo.png';
import SMARTKARGOLOGO from '@assets/images/smartkargo-logo.png';
import UPSLOGO from '@assets/images/ups-logo.png';
import USPSLOGO from '@assets/images/usps-logo.png';

import type { ITrackingCarrierMap } from '@_types/type';

const TrackingCarrierLogoMap: ITrackingCarrierMap = {
  AIRTERRA: AIRTERRALOGO,
  DHL: DHLLOGO,
  FEDEX: FEDEXLOGO,
  ONTRAC: ONTRACLOGO,
  PANDION: PANDIONLOGO,
  SMARTKARGO: SMARTKARGOLOGO,
  TFORCEFREIGHT: TFORCEFREIGHTLOGO,
  UPS: UPSLOGO,
  USPS: USPSLOGO,
  CDL: CDLLOGO,
  'OTHER CARRIER': '',
};

export default TrackingCarrierLogoMap;
