import getTrackingEvents from '../helpers/getTrackingEvents';
import type { ITrackingShipment } from '@_types/type';

const defaultShipment: ITrackingShipment = {
  trackingID: 'T10000044044455318',
  carrierCode: 'AIRTERRA',
  originCity: 'Origin City',
  originState: 'Origin State',
  originZIPCode: 'Origin ZIP Code',
  destinationCity: 'Destination City',
  destinationState: 'Destination State',
  destinationZIPCode: 'Destination ZIP Code',
  estimatedDeliveryDate: '2023-06-05',
  originalEstimatedDeliveryDate: '2023-06-05',
  hasEstimatedDeliveryDateChanged: false,
  shippedDate: '2023-06-05',
  actualDeliveryDate: '2023-06-05',
  carrierTrackingURL: 'https://track.airterra.com/',
  trackingEvents: getTrackingEvents(1),
  shipmentStatus: 'Shipped',
  orderNumber: null,
  deliveryHoldInstructions: null,
  timestampReceived: '2023-06-05',
};

export default defaultShipment;
