export const STAGING_SFCC_EINSTEIN_URL =
  'https://dev.saksoff5th.com/on/demandware.store/Sites-SaksOff5th-Site/en_US/ShiptrackEinstein-Show';

export const PRODUCTION_SFCC_EINSTEIN_URL =
  'https://saksoff5th.com/on/demandware.store/Sites-SaksOff5th-Site/en_US/ShiptrackEinstein-Show';

export default {
  STAGING_SFCC_EINSTEIN_URL,
  PRODUCTION_SFCC_EINSTEIN_URL,
};
