// @ts-nocheck

export const survey = (id) => {
  function neb_addEventListener(elem: any, eventType: string, handler: any) {
    if (elem.addEventListener) {
      elem.addEventListener(eventType, handler, false);
    } else if (elem.attachEvent) {
      elem.attachEvent(`on${eventType}`, handler);
    }
  }
  const scriptSrc = 'https://nebula-cdn.kampyle.com/wu/323067/onsite/embed.js';
  const formId = id;

  /* Self-contained script for MEDALLIA survey. Update formId and scriptSrc for your banner as needed. To stop functionality, simply remove this script */
  function showSurvey() {
    KAMPYLE_ONSITE_SDK.loadForm(formId);
    KAMPYLE_ONSITE_SDK.showForm(formId);
  }
  if (typeof KAMPYLE_ONSITE_SDK !== 'undefined') {
    showSurvey();
  } else {
    neb_addEventListener(window, 'neb_OnsiteLoaded', showSurvey);
    const scriptTag = document.createElement('script');
    scriptTag.src = scriptSrc;
    document.body.appendChild(scriptTag);
  }
};
