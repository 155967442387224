import templateOptions from '@icon/utilities/TemplateOptions';
import getTemplateComponent from '@shared/utilities/TemplateOptions';
import React from 'react';
import type { IconProps } from '@icon/IIcon';

const Icon: React.FC<IconProps> = ({ name = '', ...restProps }) => {
  const IconComponent = getTemplateComponent(templateOptions, name);

  return name ? <IconComponent {...restProps} /> : null;
};

Icon.displayName = 'Icon';

export default Icon;
