import TrackingHistoryCarrier from '@components/TrackingHistory/TrackingHistoryCarrier';
import TrackingHistoryEventList from '@components/TrackingHistory/TrackingHistoryEventList';
import TrackingHistoryDialogFooter from '@components/TrackingHistory/TrackingHistoryFooter';
import React from 'react';
import './TrackingHistoryDialogContent.css';

const TrackingHistoryDialogContent = () => (
  <>
    <div className="tracking-dialog-carrier-wrapper">
      <TrackingHistoryCarrier />
    </div>
    <div className="tracking-dialog-events-wrapper">
      <TrackingHistoryEventList />
    </div>
    <div className="tracking-dialog-footer-wrapper">
      <TrackingHistoryDialogFooter />
    </div>
  </>
);

export default TrackingHistoryDialogContent;
