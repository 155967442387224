import Shipment from '@components/OtherShipments/Shipment';
import Skeleton from '@components/OtherShipments/Skeleton';
import {
  isCurrentShipment,
  hasAtLeastTwoShipments,
} from '@components/OtherShipments/helpers';
import { ShipmentsContext } from '@context/OtherShipmentsContext';
import Typography from '@shared/components/Typography/Typography';
import React from 'react';
import type { ITrackingResponse, Nullable } from '@_types/type';
import './OtherShipments.css';

const renderShipment = (
  shipmentDetails: ITrackingResponse,
): Nullable<React.ReactNode> => {
  const { shipment } = shipmentDetails;
  if (!shipment) {
    return null;
  }
  const { trackingID } = shipment;

  return (
    !isCurrentShipment(trackingID) && (
      <Shipment shipment={shipment} key={trackingID} />
    )
  );
};

const OtherShipments = () => {
  const otherShipments =
    React.useContext(ShipmentsContext)?.otherShipmentsResponse;
  const isOtherShipmentsLoading =
    React.useContext(ShipmentsContext)?.isOtherShipmentsLoading;

  if (isOtherShipmentsLoading) {
    return <Skeleton />;
  }
  if (!otherShipments || !hasAtLeastTwoShipments(otherShipments)) {
    return null;
  }

  return (
    <div className="other-shipments-container">
      <Typography
        className="other-shipments-title"
        fontFamily="ogg"
        component="span"
      >
        Other items in your order
      </Typography>
      <div className="other-shipments-items">
        {otherShipments.map((shipmentDetails: ITrackingResponse) =>
          renderShipment(shipmentDetails),
        )}
      </div>
    </div>
  );
};

export default OtherShipments;
