// eslint-disable-next-line
const getTemplate = (templateOptions: any, variant: string) => {
  return templateOptions.filter(
    (option: { name: string }) => option.name === variant,
  )[0];
};

/**
 *
 * @param variant The component variant we whant.
 * @param templateOptions The different variants of the component that are implemented.
 * @returns The template option and the component which is going to render.
 */
const getTemplateComponent = (
  // eslint-disable-next-line
  templateOptions: any,
  variant: string,
) => {
  const templateOption = getTemplate(templateOptions, variant);
  return templateOption && templateOption.component;
};

export default getTemplateComponent;
