import React from 'react';
import type { IHamburgerButtonProps } from '@shared/components/Header/IHeader';

const HamburgerButton = (props: IHamburgerButtonProps) => {
  const { isOpen, setIsOpen } = props;
  const handleHamburgerClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <button
      type="button"
      className="hamburger-button"
      onClick={handleHamburgerClick}
    >
      <div className={`hamburger-line top-line ${isOpen ? 'opened' : ''}`} />
      <div className={`hamburger-line middle-line ${isOpen ? 'opened' : ''}`} />
      <div className={`hamburger-line bottom-line ${isOpen ? 'opened' : ''}`} />
    </button>
  );
};

export default HamburgerButton;
