import HeaderDesktop from '@shared/components/Header/components/HeaderDesktop';
import HeaderMobile from '@shared/components/Header/components/HeaderMobile';
import NavBar from '@shared/components/NavBar';
import {
  addResizeListener,
  removeResizeListener,
} from '@shared/utilities/resizeListener';
import screenBreakpoints from '@src/constants/screenBreakpoints';
import isMobileApplication from '@utils/isMobileApplication';
import React, { useState, useEffect } from 'react';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { md } = screenBreakpoints;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= md);
    };

    handleResize();
    addResizeListener(handleResize);
    return () => removeResizeListener(handleResize);
  }, [md]);

  if (isMobileApplication()) {
    return null;
  }

  return (
    <header>
      {isMobile ? (
        <HeaderMobile />
      ) : (
        <>
          <div className="header-wrapper">
            <HeaderDesktop />
          </div>
          <NavBar />
        </>
      )}
    </header>
  );
};

export default Header;
