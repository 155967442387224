import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Heart: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5781 1.93437C19.4297 1.93437 20.6016 2.79218 21.2578 3.51406C22.0641 4.4 22.5469 5.57656 22.5469 6.65469C22.5469 7.625 22.3687 9.17656 20.8219 11.4266C19.1344 13.8875 16.1672 16.6766 12 19.7328C7.83281 16.6766 4.86562 13.8875 3.17812 11.4266C1.63125 9.17656 1.45312 7.625 1.45312 6.65469C1.45312 5.57656 1.93594 4.4 2.74219 3.51406C3.39844 2.79218 4.56562 1.93437 6.42188 1.93437C7.69219 1.93437 8.78438 2.38437 9.67031 3.26562C10.3547 3.94531 12 6.59375 12 6.59375C12 6.59375 13.65 3.94531 14.3344 3.26562C15.2203 2.38437 16.3125 1.93437 17.5781 1.93437ZM17.5781 0.528122C13.4906 0.528122 11.9953 3.99218 11.9953 3.99218C11.9953 3.99218 10.5047 0.528122 6.41719 0.528122C2.32969 0.528122 0.046875 3.93125 0.046875 6.65469C0.046875 9.38281 1.31719 13.8406 12 21.4672C22.6828 13.8406 23.9531 9.37812 23.9531 6.65469C23.9531 3.93125 21.6656 0.528122 17.5781 0.528122Z"
      fill="#212427"
    />
  </svg>
);

export default withIcon(Heart);
