import type React from 'react';

export const IHyperlinkVariant = [
  'decorate',
  'cta',
  'primary',
  'secondary',
  'accent',
] as const;
export type IHyperlinkVariantTypes = (typeof IHyperlinkVariant)[number];

export interface IHyperlinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  /** The destination url */
  href: string;
  /** Add classnames (from tailwind for example). You can add one or more in the same string */
  className?: string;
  /** Arbitrary attributes such as `aria-*` and `data-*` cpnverted to ...restProps */
  // eslint-disable-next-line
  [x: string]: any;
}

export const schema = {
  variant: 'string',
  href: 'string',
  className: 'string',
  width: 'number',
  height: 'number',
  label: 'string',
  classNameIcon: 'string',
  svgClasses: 'string',
  icon: 'object',
};

export const requiredProps = ['href'];
export const requiredIconProps = ['href', 'icon', 'srLabel'];
export const requiredIconTextProps = ['href', 'icon', 'label'];
