import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Plus: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line y1="12.0001" x2="24" y2="12.0001" stroke="#212427" strokeWidth="2" />
    <line x1="12.0005" y1="24" x2="12.0005" stroke="#212427" strokeWidth="2" />
  </svg>
);

export default withIcon(Plus);
