import React from 'react';
import './Skeleton.css';

const Skeleton = () => (
  <div className="tracking-widget-container skeleton animate-pulse">
    <div className="tracking-widget-date-container">
      <div className="tracking-widget-date-shippment-status skeleton" />
      <div className="tracking-widget-date-delivery-text skeleton" />
    </div>
    <div className="shipment-summary skeleton" />
    <div className="stepper-wrapper">
      <div className="stepper-container">
        <div className="icon-container skeleton-link">
          <div className="bubble-container skeleton" />
        </div>
        <div className="icon-container skeleton-link">
          <div className="bubble-container skeleton" />
        </div>
        <div className="icon-container skeleton-link">
          <div className="bubble-container skeleton" />
        </div>
        <div className="icon-container skeleton-link">
          <div className="bubble-container skeleton" />
        </div>
      </div>
    </div>
  </div>
);

export default Skeleton;
