import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Chevron: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.35853 0L0 1.3846L11.0069 14L22 1.386L20.6415 0L10.978 11.2588L1.35853 0Z"
      fill="#212427"
    />
  </svg>
);

export default withIcon(Chevron);
