import Typography from '@typography/Typography';
import './TrackingHistoryFooter.css';
import React from 'react';

const TrackingHistoryDialogFooter = () => (
  <Typography
    fontFamily="centra-book"
    className="tracking-history-dialog-footer"
  >
    Times are shown in the local timezone.
  </Typography>
);

export default TrackingHistoryDialogFooter;
