import footerData from '@shared/components/Footer/mockData/footer.mockData';
import Col from '@shared/components/Footer/layout/Col';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import cx from 'classnames';
import React from 'react';

const FooterLegal = () => (
  <Col
    className={cx(
      'text-center',
      'order-last',
      'w-3/4',
      'mx-auto',
      'lg:text-left',
      'lg:order-first',
      'lg:w-full',
      'lg:mx-0',
    )}
  >
    <div className={cx('font-centra-book text-gray-dark lg:float-left')}>
      <span className="text-xs">
        © {new Date().getFullYear()}, Saks OFF 5TH
      </span>
      <span className="px-3">|</span>
      <Hyperlink
        label={footerData.footerLegal.label}
        href={footerData.footerLegal.href}
        target="_blank"
        className="inline"
      />
    </div>
  </Col>
);

export default FooterLegal;
