import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const ChevronLeft: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="15"
    height="23"
    viewBox="0 0 15 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.375 1.39765L12.9533 0L0 11.3238L12.9519 22.6334L14.375 21.2358L2.81462 11.2941L14.375 1.39765Z"
      fill="#212427"
    />
  </svg>
);

export default withIcon(ChevronLeft);
