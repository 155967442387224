import orderServiceMockData from '@services/mockData/orderService.mockData';
import TRACK_NUMBER_PARAM from '@src/constants/queryParams';
import { getQueryString } from '@utils/url';
import { getProxyServerUrl, fetchResponse, isMock } from './helpers';
import type { IOrderResponse } from '@_types/type';

const getFromMock = (
  trackNumber: string | undefined,
): IOrderResponse | undefined => {
  if (!trackNumber) {
    return undefined;
  }
  return orderServiceMockData[trackNumber] || undefined;
};

const getFromApi = async (): Promise<IOrderResponse | undefined> => {
  const trackNumber = getQueryString(TRACK_NUMBER_PARAM);

  if (!trackNumber) {
    return undefined;
  }

  if (isMock(trackNumber)) {
    return orderServiceMockData[trackNumber];
  }

  return fetchResponse<IOrderResponse>(
    `${getProxyServerUrl()}/order/${trackNumber}/tracking`,
  );
};

const getOrder = async (
  trackNumber: string | undefined = undefined,
): Promise<IOrderResponse | undefined> => {
  const order: IOrderResponse | undefined = trackNumber
    ? getFromMock(trackNumber)
    : await getFromApi();

  if (!order) {
    return undefined;
  }
  return order;
};

export default getOrder;
