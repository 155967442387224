import IconFacebook from '@icon/components/IconFacebook';
import IconInstagram from '@icon/components/IconInstagram';
import IconPinterest from '@icon/components/IconPinterest';
import IconTwitter from '@icon/components/IconTwitter';
import footerData from '@shared/components/Footer/mockData/footer.mockData';
import Col from '@shared/components/Footer/layout/Col';
import HyperlinkIcon from '@shared/components/Hyperlink/HyperlinkIcon';
import cx from 'classnames';
import React from 'react';

const getIconComponent = (key: string) => {
  return (
    {
      pinterest: <IconPinterest />,
      twitter: <IconTwitter />,
      facebook: <IconFacebook />,
      instagram: <IconInstagram />,
    }[key] || <></>
  );
};

const FooterSocial = () => (
  <Col className="footer-social">
    {footerData.footerSocial.map((social, idx) => (
      <HyperlinkIcon
        key={idx}
        icon={getIconComponent(social.icon as string)}
        href={social.href}
        target="_blank"
        srLabel={social.label}
        className={cx('footer-social-link', 'hlink-icon-disabled-hover')}
        width={20}
        height={20}
      />
    ))}
  </Col>
);

export default FooterSocial;
