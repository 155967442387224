export const getVisibleItemsNumber = (
  containerWidth: number,
  productWidth: number,
): number => Math.floor(containerWidth / productWidth);

export const getTransparentClass = (
  index: number,
  visibleItemsNumber: number,
): string => (index >= visibleItemsNumber ? 'transparent' : '');

export default {
  getVisibleItemsNumber,
  getTransparentClass,
};
