import TrackingHistoryEventDate from '@components/TrackingHistory/TrackingHistoryEvent/TrackingHistoryEventDate';
import TrackingHistoryEventDescription from '@components/TrackingHistory/TrackingHistoryEvent/TrackingHistoryEventDescription';
import React from 'react';
import type { ITrackingEvent } from '@_types/type';
import './TrackingHistoryEvent.css';

interface ITrackingEventProps {
  trackingEvent: ITrackingEvent;
}

const TrackingHistoryEvent = (props: ITrackingEventProps) => {
  const { trackingEvent } = props;

  return (
    <div className="tracking-event">
      <TrackingHistoryEventDate eventDate={trackingEvent.eventTimestamp} />
      <TrackingHistoryEventDescription trackingEvent={trackingEvent} />
    </div>
  );
};

export default TrackingHistoryEvent;
