import cx from 'classnames';

// eslint-disable-next-line
const transitionFadeAttributes: any = {
  enter: 'ease-in-out duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'ease-in-out duration-300',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

// eslint-disable-next-line
const transitionAttributeValues: any = {
  left: {
    className: 'dialog-transition dialog-transition-left',
    enter: 'ease-in-out duration-300',
    enterFrom: '-ml-[100%]',
    enterTo: 'ml-0',
    leave: 'ease-out duration-300',
    leaveFrom: 'ml-0',
    leaveTo: '-ml-[100%]',
  },
  top: {
    className: 'dialog-transition dialog-transition-top',
    enter: 'ease-in-out duration-300',
    enterFrom: '-mt-[100%]',
    enterTo: 'mt-0',
    leave: 'ease-out duration-300',
    leaveFrom: 'mt-0',
    leaveTo: '-mt-[100%]',
  },
  center: {
    className: 'dialog-transition dialog-transition-center',
    enter: 'ease-in-out duration-300',
    enterFrom: 'mt-[20%] opacity-0',
    enterTo: 'mt-0 opacity-100',
    leave: 'ease-out duration-300',
    leaveFrom: 'mt-0 opacity-100',
    leaveTo: 'mt-[20%] opacity-0',
  },
  right: {
    className: 'dialog-transition dialog-transition-right',
    enter: 'ease-in-out duration-300',
    enterFrom: '-mr-[100%]',
    enterTo: 'mr-0',
    leave: 'ease-out duration-300',
    leaveFrom: 'mr-0',
    leaveTo: '-mr-[100%]',
  },
  bottom: {
    className: 'dialog-transition dialog-transition-bottom',
    enter: 'ease-in-out duration-300',
    enterFrom: 'bottom-[-100%]',
    enterTo: 'bottom-0',
    leave: 'ease-out duration-300',
    leaveFrom: 'bottom-0',
    leaveTo: 'bottom-[-100%]',
  },
};

/**
 * Returns the dialog attributes
 *
 * @param props DialogProps
 * @returns {object}
 */
const createAttributes = (
  variant: string,
  panelClassName: string,
  transitionClassName: string,
) => ({
  transitionFadeAttributes,
  dialogOverlayAttributes: { className: 'dialog-overlay' },
  dialogAttributes: {
    className: cx('dialog-attributes', `dialog-attributes-${variant}`),
  },
  transitionAttributes: {
    ...Object.assign(transitionAttributeValues[variant], {
      className: cx(
        transitionAttributeValues[variant]?.className,
        transitionClassName,
      ),
    }),
  },
  panelAttributes: {
    className: cx('dialog-panel', `dialog-panel-${variant}`, panelClassName),
  },
});

export default createAttributes;
