import type { ITrackingEvent } from '@_types/type';

const trackingEventFull: ITrackingEvent = {
  eventTimestamp: '2023-06-05T19:08:00',
  statusCode: '',
  statusCategory: '',
  statusDescription: 'Out for Delivery',
  eventCity: 'Silver Spring',
  eventState: 'MD',
  eventZIPCode: '20901',
  timestampReceived: '',
  estimatedDeliveryDate: '',
  normalizedShipmentStatus: '',
};

const trackingEventPartial: ITrackingEvent = {
  eventTimestamp: '2023-06-05T19:08:00',
  statusCode: '',
  statusCategory: '',
  statusDescription: 'Arrived at USPS Regional Destination Facility',
  eventCity: 'BALTIMORE MD DISTRIBUTION CENTER',
  eventState: '',
  eventZIPCode: '',
  timestampReceived: '',
  estimatedDeliveryDate: '',
  normalizedShipmentStatus: '',
};

const getTrackingEvents = (len: number): ITrackingEvent[] => {
  const trackingEvents = [];
  for (let i = 0; i < len; i++) {
    trackingEvents.push(trackingEventFull);
  }
  trackingEvents.push(trackingEventPartial);
  return trackingEvents;
};

export default getTrackingEvents;
