import { getPriceText } from '@components/ShipmentItems/ProductItem/helpers';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import Typography from '@typography/Typography';
import React from 'react';
import './ProductItem.css';
import type { IProduct } from '@_types/type';

const ProductItem = (props: IProduct) => {
  const { image, brand, price, quantity, slugUrl } = props;
  const priceText = getPriceText(price, quantity);

  return (
    <Hyperlink
      href={slugUrl}
      className="product-item-container"
      target={ANCHOR_TARGET}
      rel="noreferrer"
    >
      <div className="product-item-image">
        <img src={image.link} alt={brand} />
      </div>
      <div className="product-item-description">
        <Typography
          className="product-item-designer"
          fontFamily="centra-medium"
          component="h3"
        >
          {brand}
        </Typography>
        <Typography
          className="product-item-price"
          fontFamily="centra-book"
          component="span"
        >
          {priceText}
        </Typography>
      </div>
    </Hyperlink>
  );
};

export default ProductItem;
