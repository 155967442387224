import CheckIcon from '@components/TrackingWidget/Stepper/icons/CheckIcon';
import DelayedIcon from '@components/TrackingWidget/Stepper/icons/DelayedIcon';
import NumberIcon from '@components/TrackingWidget/Stepper/icons/NumberIcon';
import ShipmentStatus from '@root/src/components/enums';
import React from 'react';
import type { ITrackingShipment, ITrackingStepperItem } from '@_types/type';

const DELAYED = 'Delayed';
const DELIVERY_EXCEPTION = 'Delivery Exception';
const OUT_FOR_DELIVERY = 'Out for Delivery';
const COMMON_STAGE_STATUSES = ['Ordered', 'Shipped', 'Delivered'];
const DELIVERY_EXCEPTION_INDEX = 2;

interface IStageIconProps {
  stage: ITrackingStepperItem;
  stageIndex: number;
  currentStageIndex: number;
}

type IStageDescriptionFontFamily = 'centra-book' | 'centra-bold';

export const getStageIcon = (props: IStageIconProps) => {
  const { stage, stageIndex, currentStageIndex } = props;
  const { active, description } = stage;

  if (active && description === DELAYED) {
    return <DelayedIcon />;
  }

  if (active) {
    return <CheckIcon isCurrentStage={currentStageIndex === stageIndex} />;
  }

  return <NumberIcon stageIndex={stageIndex} />;
};

export const getCurrentStageIndex = (stages: ITrackingStepperItem[]) =>
  stages.map((stage) => stage.active === true).lastIndexOf(true);

export const getFontWeight = (isCurrentStage: boolean): string =>
  isCurrentStage ? 'font-bold' : 'font-normal';

export const getFontFamily = (
  isCurrentStage: boolean,
): IStageDescriptionFontFamily =>
  isCurrentStage ? 'centra-bold' : 'centra-book';

export const getClassNameWhenStageActive = (stageActive: boolean) =>
  stageActive ? 'active' : '';

const getCurrentStageStatuses = (shipmentStatus: string): string[] => {
  const currentStageStatuses = [...COMMON_STAGE_STATUSES];
  const deliveryExceptionValue =
    shipmentStatus === DELIVERY_EXCEPTION ? DELAYED : OUT_FOR_DELIVERY;

  currentStageStatuses.splice(
    DELIVERY_EXCEPTION_INDEX,
    0,
    deliveryExceptionValue,
  );

  return currentStageStatuses;
};

export const generateStages = (
  shipmentStatus: string,
): ITrackingStepperItem[] => {
  const lastActiveStatus =
    shipmentStatus === DELIVERY_EXCEPTION ? DELAYED : shipmentStatus;
  const currentStageStatuses = getCurrentStageStatuses(shipmentStatus);

  const stages: ITrackingStepperItem[] = currentStageStatuses.map(
    (stage, index) => ({
      active: index <= currentStageStatuses.indexOf(lastActiveStatus),
      description: stage,
    }),
  );

  return stages;
};

export const renderStages = (
  stages: ITrackingStepperItem[],
  shipment: ITrackingShipment,
): boolean => {
  if (stages.length === 0) {
    return false;
  }

  if (shipment.shipmentStatus === ShipmentStatus.Ordered) {
    return false;
  }

  if (
    shipment.estimatedDeliveryDate ||
    shipment.shipmentStatus === ShipmentStatus.Delivered ||
    shipment.shipmentStatus === ShipmentStatus.DeliveryException ||
    shipment.shipmentStatus === ShipmentStatus.OutForDelivery
  ) {
    return true;
  }

  return false;
};

export default {
  getStageIcon,
  getCurrentStageIndex,
  getFontWeight,
  getClassNameWhenStageActive,
  getFontFamily,
  generateStages,
  renderStages,
};
