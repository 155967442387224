import getOtherShipments from '@services/mockData/helpers/getOtherShipments';
import { isMock, getProxyServerUrl, fetchResponse } from './helpers';
import type { ITrackingResponse, IGetShipmentsProps } from '@_types/type';

const getFromMock = (
  tracking: IGetShipmentsProps,
): ITrackingResponse[] | undefined => getOtherShipments(tracking);

const getFromApi = async (
  tracking: IGetShipmentsProps,
): Promise<ITrackingResponse[] | undefined> => {
  if (!tracking) {
    return undefined;
  }
  const fetchData = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tracking),
  };
  return fetchResponse<ITrackingResponse[]>(
    `${getProxyServerUrl()}/shipment/events`,
    fetchData,
  );
};

const getShipments = async (
  tracking: IGetShipmentsProps = { trackingIDs: [] },
): Promise<ITrackingResponse[] | undefined> => {
  const { trackingIDs } = tracking;
  const shipments: ITrackingResponse[] | undefined = trackingIDs.every(isMock)
    ? getFromMock(tracking)
    : await getFromApi(tracking);

  if (!shipments) {
    return undefined;
  }
  return shipments;
};

export default getShipments;
