import DeliveryMissing from '@components/DeliveryMissing';
import EinsteinIframe from '@components/EinsteinIframe';
import OrderWrapper from '@components/OrderWrapper';
import Footer from '@shared/components/Footer/Footer';
import Header from '@shared/components/Header';
import TRACK_NUMBER_PARAM from '@src/constants/queryParams';
import utilsUrl from '@utils/url';
import React from 'react';
import './App.css';

const App = () => {
  const trackNumber = utilsUrl.getQueryString(TRACK_NUMBER_PARAM);

  return trackNumber ? (
    <>
      <Header />
      <main>
        <OrderWrapper />
        <EinsteinIframe />
      </main>
      <Footer showLoyalty />
    </>
  ) : (
    <DeliveryMissing />
  );
};

export default App;
