import pascalToHyphenCase from '@shared/utilities/string';
import React from 'react';
import type { IconProps } from '@icon/IIcon';

const withIcon = (WrappedComponent: React.FC) => {
  const WithIconWrapper = (props: IconProps) => {
    const {
      width = 24,
      height = 24,
      srLabel = '',
      color = 'none',
      className = '',
      svgClasses = '',
      ...restProps
    } = props;

    const decorativeAttributes = {
      className: `icon icon-so5-${
        pascalToHyphenCase(WrappedComponent.name) || ''
      } inline-block relative leading-[0] transition-filter duration-150 ${className}`,
    };

    const svgAttributes = {
      width,
      height,
      'aria-hidden': true,
      className: svgClasses,
      fill: color,
    };

    const srLabelElement: JSX.Element | null = srLabel ? (
      <span className="sr-only">{srLabel}</span>
    ) : null;

    return (
      <span {...decorativeAttributes}>
        <WrappedComponent {...restProps} {...svgAttributes} />
        {srLabelElement}
      </span>
    );
  };
  return WithIconWrapper;
};

export default withIcon;
