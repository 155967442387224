import * as React from 'react';
import withIcon from '../withIcon';
import type { IconProps } from '../IIcon';

const Facebook: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8502 6.2C16.4502 6.2 17.5002 6.2 18.5502 6.2C18.5502 5.6 18.5502 3.8 18.5502 2C17.2002 2 15.5502 2 14.8002 2C9.7002 2 9.8502 6.05 9.8502 6.65C9.8502 7.25 9.8502 9.95 9.8502 9.95H7.4502V14H9.8502V26H14.8002V14H18.1002C18.1002 14 18.4002 12.05 18.5502 9.95C18.1002 9.95 14.8002 9.95 14.8002 9.95C14.8002 9.95 14.8002 7.55 14.8002 7.25C14.8002 6.8 15.4002 6.2 15.8502 6.2Z"
      fill="#6E6E6E"
    />
  </svg>
);

export default withIcon(Facebook);
