import NextArrow from '@carousel/components/NextArrow/NextArrow';
import PrevArrow from '@carousel/components/PrevArrow/PrevArrow';
import screenBreakpoints from '@shared/constants/screenBreakpoints';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import type { ICarouselProps } from '@carousel/ICarousel';

const Carousel = (props: ICarouselProps) => {
  const {
    children,
    showArrows = true,
    showDots = true,
    infinite = false,
    responsive = null,
  } = props;
  const { sm, md, lg } = screenBreakpoints;

  const settings = {
    dots: showDots,
    arrows: showArrows,
    infinite,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dotsClass: 'custom-slick-dots',
    responsive: responsive || [
      {
        breakpoint: lg,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: md,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: sm,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="carousel-container">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default Carousel;
