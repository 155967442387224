import {
  buttonType,
  createAttributes,
} from '@button/utilities/CreateAttributes';
import IconClose from '@icon/components/IconClose';
import * as React from 'react';
import type { IButtonProps } from '@button/IButton';

// NOTE: We extend the common button props and add custom props below
export interface ButtonCloseProps extends IButtonProps {
  /** Defines an optional screen reader message */
  srLabel?: string;
}

export const ButtonClose: React.FC<ButtonCloseProps> = (
  props: ButtonCloseProps,
) => {
  const { srLabel } = props;
  const attributes = createAttributes(props, buttonType.CLOSE);

  return (
    <button type="button" {...attributes}>
      <IconClose srLabel={srLabel} />
    </button>
  );
};

ButtonClose.defaultProps = {
  srLabel: 'Close',
};
