import TextDiv from '@typography/components/TextDiv';
import TextH1 from '@typography/components/TextH1';
import TextH2 from '@typography/components/TextH2';
import TextH3 from '@typography/components/TextH3';
import TextH4 from '@typography/components/TextH4';
import TextP from '@typography/components/TextP';
import TextSpan from '@typography/components/TextSpan';
import type { Component, ITextTemplateProps } from '@typography/ITypography';
import type React from 'react';

type TemplateOptionsType = {
  [key in Component]: React.FC<ITextTemplateProps>;
};
const TemplateOptions: TemplateOptionsType = {
  h1: TextH1,
  h2: TextH2,
  h3: TextH3,
  h4: TextH4,
  div: TextDiv,
  span: TextSpan,
  p: TextP,
};

export default TemplateOptions;
