import TrackingHistoryCarrierLogo from '@components/TrackingHistory/TrackingHistoryCarrierLogo';
import { TrackingContext } from '@context/TrackingContext';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import Typography from '@typography/Typography';
import { getHtmlCodeSymbolText } from '@utils/htmlCodePaser';
import React from 'react';
import './TrackingHistoryCarrier.css';

const TrackingHistoryCarrier = () => {
  const trackingResponse = React.useContext(TrackingContext)?.trackingResponse;
  if (!(trackingResponse && trackingResponse.shipment)) {
    return null;
  }

  const { trackingID, carrierCode, carrierTrackingURL } =
    trackingResponse.shipment;

  const trackingNumberSymbolText = getHtmlCodeSymbolText('HASHTAG_SIGN');

  return (
    <div className="tracking-history-carrier">
      <div className="carrier-logo-wrapper">
        <TrackingHistoryCarrierLogo carrier={carrierCode} />
      </div>
      <Typography fontFamily="centra-book" className="carrier-info">
        <span>Tracking {trackingNumberSymbolText}</span>
        <Hyperlink
          label={trackingID}
          className="carrier-tracking-number"
          href={carrierTrackingURL || ''}
          target={ANCHOR_TARGET}
          rel="noreferrer"
        />
      </Typography>
    </div>
  );
};

export default TrackingHistoryCarrier;
