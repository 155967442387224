import {
  addMessageListener,
  removeMessageListener,
} from '@shared/utilities/messageListener';
import {
  addResizeListener,
  removeResizeListener,
} from '@shared/utilities/resizeListener';
import { PARENT_TARGET } from '@src/constants/anchorTargets';
import { debounce } from 'lodash';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import type {
  IIframeProps,
  IParentMessageData,
  IEinsteinMessageData,
} from '@components/EinsteinIframe/Iframe/IIframe';
import './Iframe.css';

const Iframe: React.FC<IIframeProps> = (props: IIframeProps) => {
  const { url, title } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<string>('0px');
  const MSG_TYPE_REQUIRE_IFRAME_HEIGHT = 'requireIframeHeight';
  const MSG_TYPE_CHANGE_ELEMENT = 'changeIframeElement';

  const iframePostMessage = (messageData: IParentMessageData) => {
    const iframeEle = iframeRef.current;
    iframeEle?.contentWindow?.postMessage(messageData, '*');
  };

  const requireIframeHeight = useCallback(() => {
    const messageData: IParentMessageData = {
      parentDomain: window.origin,
      type: MSG_TYPE_REQUIRE_IFRAME_HEIGHT,
    };
    iframePostMessage(messageData);
  }, []);

  const changeIframeElement = () => {
    const messageData: IParentMessageData = {
      parentDomain: window.origin,
      type: MSG_TYPE_CHANGE_ELEMENT,
      target: PARENT_TARGET,
    };
    iframePostMessage(messageData);
  };

  const iframeOnload = async () => {
    requireIframeHeight();
    changeIframeElement();
  };

  const handleIframeHeight = (height: string) => {
    setIframeHeight(height);
  };

  useEffect(() => {
    const handleIframeHeightEvent = (event: IEinsteinMessageData) => {
      const isIframeHeightMessage =
        Object.prototype.toString.call(event.data) === '[object Object]' &&
        event.origin === event.data.childDomain &&
        event.data.type === MSG_TYPE_REQUIRE_IFRAME_HEIGHT;
      if (isIframeHeightMessage) {
        handleIframeHeight(`${event.data.height}px`);
      }
    };
    const debounceRequireIframeHeight = debounce(requireIframeHeight, 500);
    handleIframeHeight('0px');
    addResizeListener(debounceRequireIframeHeight);
    addMessageListener(handleIframeHeightEvent);
    return () => {
      removeResizeListener(debounceRequireIframeHeight);
      removeMessageListener(handleIframeHeightEvent);
    };
  }, [requireIframeHeight]);

  return (
    <iframe
      title={title}
      src={url}
      style={{ width: '100%', height: iframeHeight }}
      scrolling="no"
      ref={iframeRef}
      id="einsteinIframe"
      onLoad={iframeOnload}
    />
  );
};

export default Iframe;
