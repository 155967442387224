import type { IProduct } from '@_types/type';

interface IOrderShipment {
  trackingNumber: string;
  products: IProduct[];
}
const getTrackingIDs = (shipments: IOrderShipment[]) =>
  shipments.map((shipment) => shipment.trackingNumber);

export default getTrackingIDs;
