import cx from 'classnames';
import React from 'react';
import usePropValidation from '@shared/utilities/hooks/usePropValidation';
import { requiredIconProps, schema } from '@shared/components/Hyperlink/IHyperlink';
import createAttributes, { hyperlinkType } from '@shared/components/Hyperlink/utilities/CreateAttributes';
import type { IHyperlinkProps } from '@shared/components/Hyperlink/IHyperlink';
import type { FC } from 'react';

export interface HyperlinkIconProps extends IHyperlinkProps {
  /** Defines the icon component */
  icon: JSX.Element;
  /** The label for screen readers */
  srLabel: string;
  /** Indicates the width of the Icon */
  width?: number;
  /** Indicates the height of the Icon */
  height?: number;
  /** Add classnames (from tailwind for example) for the svg. */
  svgClasses?: string;
}

export const HyperlinkIcon: FC<HyperlinkIconProps> = (props) => {
  const shouldRender = usePropValidation(
    'HyperlinkIcon',
    props,
    requiredIconProps,
    schema,
  );
  const { icon, width, height, srLabel, color, svgClasses } = props;
  const attributes = createAttributes(props, hyperlinkType.ICON);
  const svgAttributes = {
    width,
    height,
    srLabel,
    color,
    svgClasses: cx(svgClasses, 'hlink-svg'),
  };
  if (!shouldRender) return null;

  const iconComponent = React.isValidElement(icon)
    ? React.cloneElement(icon, { ...svgAttributes })
    : icon;

  return <a {...attributes}>{iconComponent}</a>;
};

HyperlinkIcon.displayName = 'HyperlinkIcon';

export default HyperlinkIcon;
