import trackingServiceMockData from '@services/mockData/trackingService.mockData';
import TRACK_NUMBER_PARAM from '@src/constants/queryParams';
import { getQueryString } from '@utils/url';
import {
  getProxyServerUrl,
  fetchResponse,
  isMock,
  sortEventDatesDesc,
} from './helpers';

import type { ITrackingResponse, IGetShipmentsProps } from '@_types/type';

const getFromMock = (
  trackNumber: string | undefined,
): ITrackingResponse[] | undefined => {
  if (!trackNumber) {
    return undefined;
  }
  return [trackingServiceMockData[trackNumber]] || undefined;
};

const getFromApi = async (): Promise<ITrackingResponse[] | undefined> => {
  const trackNumber = getQueryString(TRACK_NUMBER_PARAM);

  if (!trackNumber) {
    return undefined;
  }

  if (isMock(trackNumber)) {
    return [trackingServiceMockData[trackNumber]];
  }

  const tracking: IGetShipmentsProps = {
    trackingIDs: [trackNumber],
  };

  const fetchData = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tracking),
  };

  return fetchResponse<ITrackingResponse[]>(
    `${getProxyServerUrl()}/shipment/events`,
    fetchData,
  );
};

const getTracking = async (
  trackNumber: string | undefined = undefined,
): Promise<ITrackingResponse | undefined> => {
  const tracking: ITrackingResponse[] | undefined = trackNumber
    ? getFromMock(trackNumber)
    : await getFromApi();

  if (!tracking) {
    return undefined;
  }

  const [trackingResponse] = tracking;
  let trackingEvents = trackingResponse?.shipment?.trackingEvents;
  if (trackingEvents) {
    trackingEvents = sortEventDatesDesc(trackingEvents);
  }
  return trackingResponse;
};

export default getTracking;
