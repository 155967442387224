import Typography from '@typography/Typography';
import React from 'react';
import './TrackingHistoryTitle.css';

const TrackingHistoryTitle = () => (
  <Typography
    component="h1"
    align="center"
    fontFamily="ogg-bold"
    className="tracking-history-dialog-title"
  >
    Tracking History
  </Typography>
);

export default TrackingHistoryTitle;
