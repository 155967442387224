import Dialog from '@dialog/Dialog';
import Icon from '@icon/Icon';
import HamburgerButton from '@shared/components/Header/components/HamburgerButton';
import HEADER_LINKS from '@shared/components/Header/constants/headerLinks';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import NavBar from '@shared/components/NavBar';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import React, { useState } from 'react';

const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { LOGO_LINK, WISHLIST_LINK, MY_ACCOUNT_LINK } = HEADER_LINKS;
  return (
    <>
      <div className="header-container-mobile">
        <div className="hamburger-container">
          <HamburgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div className="logo-container">
          <Hyperlink href={LOGO_LINK} target={ANCHOR_TARGET}>
            <Icon name="so5" className="logo-icon" />
          </Hyperlink>
        </div>
        <div className="header-links-mobile">
          <Hyperlink href={WISHLIST_LINK} target={ANCHOR_TARGET}>
            <Icon
              name="heart"
              width={24}
              height={21}
              className="wishlist-icon"
            />
          </Hyperlink>
          <Hyperlink href={MY_ACCOUNT_LINK} target={ANCHOR_TARGET}>
            <Icon
              name="profile"
              width={27}
              height={27}
              className="profile-icon"
            />
          </Hyperlink>
        </div>
      </div>
      <Dialog
        id="hamburgerMenu"
        variant="left"
        showDialog={isOpen}
        setShowDialog={setIsOpen}
        panelClassName="dialog-menu-container"
        showCloseButton={false}
      >
        <div className="navigation-bar-container">
          <NavBar />
        </div>
      </Dialog>
    </>
  );
};

export default HeaderMobile;
