import getTracking from '@services/trackingService';
import isEmptyObject from '@utils/isEmptyObject';
import React, { createContext, useState, useEffect, useMemo } from 'react';
import type {
  ITrackingContextProps,
  ITrackingResponse,
  Nullable,
} from '@_types/type';

interface ITrackingContextProviderProps {
  children: React.ReactNode;
  initialValue?: string | undefined;
}

export const TrackingContext =
  createContext<Nullable<ITrackingContextProps>>(null);

export const TrackingContextProvider = ({
  children,
  initialValue,
}: React.PropsWithChildren<ITrackingContextProviderProps>) => {
  const [trackingResponse, setTrackingResponse] =
    useState<Nullable<ITrackingResponse>>(null);
  const [isTrackingLoading, setIsTrackingLoading] = useState(true);
  useEffect(() => {
    const callAPI = async () => {
      try {
        const tracking = await getTracking(initialValue);
        if (!tracking || isEmptyObject(tracking)) {
          throw new Error('Tracking not found');
        }
        setTrackingResponse(tracking);
      } catch {
        setTrackingResponse(null);
      }

      setIsTrackingLoading(false);
    };
    callAPI();
  }, [initialValue]);

  const contextValue = useMemo(
    () => ({
      trackingResponse,
      isTrackingLoading,
    }),
    [isTrackingLoading, trackingResponse],
  );

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

TrackingContextProvider.defaultProps = {
  initialValue: undefined,
};

export default { TrackingContext, TrackingContextProvider };
