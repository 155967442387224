import Icon from '@icon/Icon';
import HEADER_LINKS from '@shared/components/Header/constants/headerLinks';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import Typography from '@typography/Typography';
import React from 'react';

const HeaderDesktop = () => {
  const { LOGO_LINK, WISHLIST_LINK, MY_ACCOUNT_LINK } = HEADER_LINKS;
  return (
    <div className="header-container">
      <Hyperlink href={LOGO_LINK} target={ANCHOR_TARGET}>
        <Icon name="so5" width={110} height={54} className="logo-icon" />
      </Hyperlink>
      <div className="header-links">
        <Hyperlink href={WISHLIST_LINK} target={ANCHOR_TARGET}>
          <Icon name="heart" width={24} height={21} className="wishlist-icon" />
        </Hyperlink>
        <Hyperlink
          href={MY_ACCOUNT_LINK}
          className="my-account-link"
          target={ANCHOR_TARGET}
        >
          <Typography
            component="span"
            font-family="centra-book"
            className="my-account-text"
          >
            My Account
          </Typography>
        </Hyperlink>
      </div>
    </div>
  );
};

export default HeaderDesktop;
