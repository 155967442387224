import type { INavBarCategories } from '@shared/components/NavBar/INavBar';

const CATEGORIES: INavBarCategories[] = [
  {
    name: 'just in',
    link: 'https://www.saksoff5th.com/c/women/new-arrivals',
  },
  {
    name: 'women',
    link: 'https://www.saksoff5th.com/c/women',
  },
  {
    name: 'men',
    link: 'https://www.saksoff5th.com/c/men',
  },
  {
    name: 'shoes',
    link: 'https://www.saksoff5th.com/c/women/shoes',
  },
  {
    name: 'handbags',
    link: 'https://www.saksoff5th.com/c/women/handbags',
  },
  {
    name: 'jewelry',
    link: 'https://www.saksoff5th.com/c/women/jewelry',
  },
  {
    name: 'accessories',
    link: 'https://www.saksoff5th.com/c/women/accessories',
  },
  {
    name: 'beauty',
    link: 'https://www.saksoff5th.com/c/beauty',
  },
  {
    name: 'kids',
    link: 'https://www.saksoff5th.com/c/kids',
  },
  {
    name: 'home',
    link: 'https://www.saksoff5th.com/c/home',
  },
  {
    name: 'clearance',
    link: 'https://www.saksoff5th.com/search/clearance?cgid=root',
    redAccent: true,
  },
];

export default CATEGORIES;
