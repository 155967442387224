import Typography from '@typography/Typography';
import { getDateToString } from '@utils/formatting';
import React from 'react';
import type { IShipmentSummaryProps } from '@components/OtherShipments/IOtherShipments';
import './ShipmentSummary.css';

const ESTIMATED_DELIVERY = 'Estimated Delivery';
const ORDERED = 'Ordered';

const ShipmentSummary = (props: IShipmentSummaryProps) => {
  const estimatedDelivery = props?.estimatedDelivery;
  const otherShipmentsSummaryText = estimatedDelivery
    ? ESTIMATED_DELIVERY
    : ORDERED;

  return (
    <div className="other-shipments-summary-container">
      <Typography
        fontFamily="centra-book"
        component="span"
        className="other-shipments-summary"
      >
        {otherShipmentsSummaryText}
      </Typography>
      {estimatedDelivery && (
        <Typography
          fontFamily="centra-bold"
          component="span"
          className="other-shipments-date"
        >
          {getDateToString(estimatedDelivery, 'dddd, MMM D')}
        </Typography>
      )}
    </div>
  );
};

export default ShipmentSummary;
