import usePropValidation from '@shared/utilities/hooks/usePropValidation';
import * as React from 'react';
import { requiredTextProps, schema } from './IButton';
import { createAttributes, buttonType } from './utilities/CreateAttributes';
import type { IButtonProps } from './IButton';

// NOTE: We extend the common button props and add custom props below
export interface ButtonTextProps extends IButtonProps {
  /** Defines the inner text of the button */

  label: string;
}

export const ButtonText: React.FC<ButtonTextProps> = (
  props: ButtonTextProps,
) => {
  const shouldRender = usePropValidation(
    'ButtonText',
    props,
    requiredTextProps,
    schema,
  );

  if (!shouldRender) return null;

  const { label } = props;
  const attributes = createAttributes(props, buttonType.TEXT);
  return (
    <button type="button" {...attributes}>
      <span className="btn-label">{label}</span>
    </button>
  );
};
