import screenBreakpoints from '@constants/screenBreakpoints';

const getCurrentBreakpoint = (screenWidth: number): string => {
  const { sm, md, lg } = screenBreakpoints;

  if (screenWidth < sm) return 'sm';
  if (screenWidth < md) return 'md';
  if (screenWidth < lg) return 'lg';
  return 'xl';
};

export default getCurrentBreakpoint;
