import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Close: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#212427"
    {...props}
  >
    <path d="M24.5 1.5L1.5 24.5" strokeWidth="2" />
    <path d="M1.5 1.5L24.5 24.5" strokeWidth="2" />
  </svg>
);

export default withIcon(Close);
