import ShipmentItems from '@components/OtherShipments/ShipmentItems';
import ShipmentSummary from '@components/OtherShipments/ShipmentSummary';
import TrackShipment from '@components/OtherShipments/TrackShipment';
import { OrderContext } from '@context/OrderContext';
import React from 'react';
import type { ITrackingResponse } from '@_types/type';
import './Shipment.css';

const Shipment = (props: ITrackingResponse) => {
  const orderResponse = React.useContext(OrderContext)?.orderResponse;
  const { shipment } = props;
  if (!shipment) {
    return null;
  }
  const { estimatedDeliveryDate, trackingID } = shipment;
  const shipmentProducts =
    orderResponse?.shipments.find(
      (orderShipment) => orderShipment.trackingNumber === trackingID,
    )?.products ?? null;

  return (
    <div className="shipment-container">
      <ShipmentSummary estimatedDelivery={estimatedDeliveryDate} />
      <ShipmentItems shipmentProducts={shipmentProducts} />
      {estimatedDeliveryDate && <TrackShipment trackingID={trackingID} />}
    </div>
  );
};

export default Shipment;
