import {
  getVisibleItemsNumber,
  getTransparentClass,
} from '@components/OtherShipments/ShipmentItems/helpers';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import {
  addResizeListener,
  removeResizeListener,
} from '@shared/utilities/resizeListener';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import React, { useRef, useEffect, useState } from 'react';
import './ShipmentItems.css';
import type { IShipmentItemsProps } from '@components/OtherShipments/IOtherShipments';

const ShipmentItems = (props: IShipmentItemsProps) => {
  const [visibleItems, setVisibleItems] = useState<number>(0);
  const [productWidth, setProductWidth] = useState<number>(0);
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const productRef = useRef<HTMLDivElement>(null);

  const { shipmentProducts } = props;

  useEffect(() => {
    const handleResize = () => {
      if (productRef.current) {
        setProductWidth(productRef.current.offsetWidth);
      }
      if (itemsContainerRef.current) {
        setVisibleItems(
          getVisibleItemsNumber(
            itemsContainerRef.current.offsetWidth,
            productWidth,
          ),
        );
      }
    };
    handleResize();
    addResizeListener(handleResize);
    return () => removeResizeListener(handleResize);
  }, [productWidth]);

  if (!shipmentProducts) {
    return null;
  }
  return (
    <div className="other-shipments-items-container" ref={itemsContainerRef}>
      {shipmentProducts.map((shipmentProduct, index) => (
        <div className="other-shipments-product-item-wrapper" ref={productRef}>
          <Hyperlink
            href={shipmentProduct.slugUrl}
            className={`other-shipments-product-item-container ${getTransparentClass(
              index,
              visibleItems,
            )}`}
            target={ANCHOR_TARGET}
            rel="noreferrer"
          >
            <div className="other-shipments-product-item-image">
              <img
                src={shipmentProduct.image.link}
                alt={shipmentProduct.brand}
              />
            </div>
          </Hyperlink>
        </div>
      ))}
    </div>
  );
};

export default ShipmentItems;
