import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

interface IColProps {
  className?: string;
  children: ReactNode;
}

const Col = ({ className, children, ...restProps }: IColProps) => {
  const colClasses = classNames('column', className);

  return (
    <div className={colClasses} {...restProps}>
      {children}
    </div>
  );
};

export default Col;
