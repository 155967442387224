import { Transition } from '@headlessui/react';
import HEADER_LINKS from '@shared/components/Header/constants/headerLinks';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import Typography from '@typography/Typography';
import { getHtmlCodeSymbolText } from '@utils/htmlCodePaser';
import React, { useEffect, useState } from 'react';
import './DeliveryMissing.css';

const DELIVERY_MISSING_DIALOG_TITLE_TEXT =
  'Your delivery status is not available here.';

const getDeliveryMissingDialogDescription = () => {
  const spaceSymbolText = getHtmlCodeSymbolText('SPACE_SIGN');
  const { MY_ACCOUNT_LINK } = HEADER_LINKS;

  return (
    <Typography
      fontFamily="centra-book"
      weight="normal"
      align="center"
      color="shadow"
      component="p"
      className="delivery-missing-modal-description"
    >
      Please refer to the tracking link in your shipping confirmation email or
      your{spaceSymbolText}
      <Hyperlink
        className="delivery-missing-modal-description-link"
        href={MY_ACCOUNT_LINK}
        target={ANCHOR_TARGET}
        rel="noreferrer"
      >
        account page
      </Hyperlink>
      {spaceSymbolText}for more information about your order.
    </Typography>
  );
};

const DeliveryMissing = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <div className="delivery-missing-modal-container">
      <Transition
        show={isOpen}
        className="delivery-transition delivery-transition-center"
        enter="ease-in-out duration-300"
        enterFrom="mt-[20%] opacity-0"
        enterTo="mt-0 opacity-100"
        leave="ease-out duration-300"
        leaveFrom="mt-0 opacity-100"
        leaveTo="mt-[20%] opacity-0"
      >
        <div className="delivery-missing-modal">
          <Typography
            fontFamily="centra-book"
            weight="medium"
            component="h3"
            align="center"
            color="shadow"
            className="delivery-missing-modal-title"
          >
            {DELIVERY_MISSING_DIALOG_TITLE_TEXT}
          </Typography>
          {getDeliveryMissingDialogDescription()}
        </div>
      </Transition>
    </div>
  );
};

export default DeliveryMissing;
