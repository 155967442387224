import cx from 'classnames';

/** Defines the button type used to determine the associated attributes */
export const buttonType = {
  TEXT: 'text',
  ICON: 'icon',
  ICON_TEXT: 'icon-text',
  CLOSE: 'close',
};

/**
 * Returns an object with button classes
 *
 * NOTE: We define CSS classes rather than use Tailwind utilities in the component.
 * This is to avoid having to using JavaScript to determine UI / UX.
 *
 * @param {...object} - spread object of ButtonProps
 * @param {string} prefix - class name prefix
 * @returns {object}
 */
const getStyleClasses = (
  // eslint-disable-next-line
  { variant, size, block, className, reverse }: any,
  btnType: string,
  prefix: string,
) => ({
  className: cx(prefix, {
    [`${prefix}-${variant}`]: variant && btnType === buttonType.TEXT,
    [`${prefix}-${buttonType.TEXT.toLocaleLowerCase()}`]:
      !variant && btnType === buttonType.TEXT,
    [`${prefix}-${buttonType.ICON}`]: btnType === buttonType.ICON,
    [`${prefix}-${buttonType.CLOSE}`]: btnType === buttonType.CLOSE,
    [`${prefix}-${buttonType.ICON_TEXT}`]: btnType === buttonType.ICON_TEXT,
    [`${prefix}-${buttonType.ICON_TEXT}-reverse`]:
      reverse && btnType === buttonType.ICON_TEXT,
    [`${prefix}-${size}`]: size,
    [`${prefix}-block`]: block,
    [`${className}`]: className,
  }),
});

/**
 * Returns the button attributes
 *
 * @param props ButtonProps
 * @returns {object}
 */
// eslint-disable-next-line
export function createAttributes(props: any, btnType: string) {
  // TODO: Find a better way to get the restProps
  const {
    disabled,
    label,
    variant,
    block,
    size,
    name,
    srLabel,
    reverse,
    className,
    icon,
    ...restProps
  } = props;
  return {
    ...(disabled && { disabled }),
    ...getStyleClasses(props, btnType, 'btn'),
    ...restProps,
  };
}
