import type * as React from 'react';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Defines classes applied to the button element */
  className?: string;
  /** Defines whether the button is enabled or disabled */
  disabled?: boolean;
}

export const schema = {
  variant: 'string',
  label: 'string',
  size: 'string',
  block: 'boolean',
  className: 'string',
  disabled: 'boolean',
  name: 'string',
  srLabel: 'string',
  reverse: 'boolean',
  icon: 'object',
};

export const requiredTextProps = ['label'];
