import React from 'react';
import './DelayedIcon.css';

const DelayedIcon = () => (
  <svg
    className="delayed-mark-svg"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0001 1.03711C6.84115 1.03711 1.03711 6.84115 1.03711 14.0001C1.03711 21.159 6.84115 26.963 14.0001 26.963C21.159 26.963 26.963 21.159 26.963 14.0001C26.963 6.84115 21.159 1.03711 14.0001 1.03711ZM11.4075 6.74081L12.4445 16.0741H15.5556L16.5927 6.74081H11.4075ZM14.0001 21.2593C15.1456 21.2593 16.0741 20.3307 16.0741 19.1853C16.0741 18.0398 15.1456 17.1112 14.0001 17.1112C12.8546 17.1112 11.926 18.0398 11.926 19.1853C11.926 20.3307 12.8546 21.2593 14.0001 21.2593Z"
    />
    <path d="M12.4445 16.0741L11.9476 16.1294L11.997 16.5741H12.4445V16.0741ZM11.4075 6.74081V6.24081H10.8488L10.9105 6.79603L11.4075 6.74081ZM15.5556 16.0741V16.5741H16.0031L16.0526 16.1294L15.5556 16.0741ZM16.5927 6.74081L17.0896 6.79603L17.1513 6.24081H16.5927V6.74081ZM1.53711 14.0001C1.53711 7.1173 7.1173 1.53711 14.0001 1.53711V0.537109C6.56501 0.537109 0.537109 6.56501 0.537109 14.0001H1.53711ZM14.0001 26.463C7.1173 26.463 1.53711 20.8829 1.53711 14.0001H0.537109C0.537109 21.4351 6.56501 27.463 14.0001 27.463V26.463ZM26.463 14.0001C26.463 20.8829 20.8829 26.463 14.0001 26.463V27.463C21.4351 27.463 27.463 21.4351 27.463 14.0001H26.463ZM14.0001 1.53711C20.8829 1.53711 26.463 7.1173 26.463 14.0001H27.463C27.463 6.56501 21.4351 0.537109 14.0001 0.537109V1.53711ZM12.9415 16.0189L11.9044 6.6856L10.9105 6.79603L11.9476 16.1294L12.9415 16.0189ZM15.5556 15.5741H12.4445V16.5741H15.5556V15.5741ZM16.0957 6.6856L15.0587 16.0189L16.0526 16.1294L17.0896 6.79603L16.0957 6.6856ZM11.4075 7.24081H16.5927V6.24081H11.4075V7.24081ZM15.5741 19.1853C15.5741 20.0546 14.8694 20.7593 14.0001 20.7593V21.7593C15.4217 21.7593 16.5741 20.6069 16.5741 19.1853H15.5741ZM14.0001 17.6112C14.8694 17.6112 15.5741 18.3159 15.5741 19.1853H16.5741C16.5741 17.7636 15.4217 16.6112 14.0001 16.6112V17.6112ZM12.426 19.1853C12.426 18.3159 13.1307 17.6112 14.0001 17.6112V16.6112C12.5785 16.6112 11.426 17.7636 11.426 19.1853H12.426ZM14.0001 20.7593C13.1307 20.7593 12.426 20.0546 12.426 19.1853H11.426C11.426 20.6069 12.5785 21.7593 14.0001 21.7593V20.7593Z" />
  </svg>
);
export default DelayedIcon;
