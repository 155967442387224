import Typography from '@typography/Typography';
import { getDateToString } from '@utils/formatting';
import './TrackingHistoryEventDate.css';
import React from 'react';

interface ITrackingHistoryEventDateProps {
  eventDate: string;
}

const TrackingHistoryEventDate = (props: ITrackingHistoryEventDateProps) => {
  const { eventDate } = props;

  const dateText = getDateToString(eventDate, 'MMM D');

  const timeText = getDateToString(eventDate, 'h:mm A');

  return (
    <div className="tracking-event-date">
      <Typography transform="uppercase" fontFamily="centra-medium">
        {dateText}
      </Typography>
      <Typography component="span" fontFamily="centra-book">
        {timeText}
      </Typography>
    </div>
  );
};

export default TrackingHistoryEventDate;
