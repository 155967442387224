import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Minus: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line y1="12" x2="24" y2="12" stroke="#212427" strokeWidth="2" />
  </svg>
);

export default withIcon(Minus);
