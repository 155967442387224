import Skeleton from '@components/TrackingWidget/Skeleton';
import Stepper from '@components/TrackingWidget/Stepper';
import Summary from '@components/TrackingWidget/Summary';
import TrackingWidgetDate from '@components/TrackingWidget/TrackingWidgetDate';
import { TrackingContext } from '@context/TrackingContext';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import Typography from '@shared/components/Typography/Typography';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import React from 'react';
import './TrackingWidget.css';
import isHelpTextNeeded from './helpers';

const TrackingWidget = () => {
  const trackingResponse = React.useContext(TrackingContext)?.trackingResponse;
  const isTrackingLoading =
    React.useContext(TrackingContext)?.isTrackingLoading;
  if (isTrackingLoading) {
    return <Skeleton />;
  }
  if (!trackingResponse) {
    return null;
  }
  const shipmentStatus = trackingResponse.shipment?.shipmentStatus ?? null;

  return (
    <div className="tracking-widget-container">
      <TrackingWidgetDate />
      <Summary />
      <div className="stepper-wrapper">
        <Stepper />
      </div>
      {isHelpTextNeeded(shipmentStatus) && (
        <Hyperlink
          className="need-help-link"
          href="https://support.saksoff5th.com/s/contactsupport"
          target={ANCHOR_TARGET}
          rel="noreferrer"
        >
          <Typography fontFamily="centra-book">
            Need help with your order?
          </Typography>
        </Hyperlink>
      )}
    </div>
  );
};

export default TrackingWidget;
