import Icon from '@icon/Icon';
import React from 'react';
import type { IArrowProps } from '@carousel/ICarousel';

const PrevArrow = (props: IArrowProps) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`${className} custom-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <Icon name="chevron-left" />
    </button>
  );
};

export default PrevArrow;
