import { TrackingContext } from '@context/TrackingContext';
import Typography from '@typography/Typography';
import React from 'react';
import getDeliverySummary from './helpers/getDeliverySummary';
import getShipmentStatus from './helpers/getShipmentStatus';
import './TrackingWidgetDate.css';

const TrackingWidgetDate = () => {
  const trackingResponse = React.useContext(TrackingContext)?.trackingResponse;
  if (!trackingResponse) {
    return null;
  }

  return (
    <div className="tracking-widget-date-container">
      <Typography
        className="tracking-widget-date-shippment-status"
        fontFamily="centra-medium"
        component="span"
      >
        {getShipmentStatus(trackingResponse)}
      </Typography>
      <Typography
        className="tracking-widget-date-delivery-text"
        fontFamily="ogg-bold"
        component="h1"
      >
        {getDeliverySummary(trackingResponse)}
      </Typography>
    </div>
  );
};

export default TrackingWidgetDate;
