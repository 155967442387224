import ShipmentStatus from '@components/enums';
import type { Nullable } from '@_types/type';

const isHelpTextNeeded = (shipmentStatus: Nullable<string>) =>
  shipmentStatus !== null &&
  [
    ShipmentStatus.DeliveryException.toString(),
    ShipmentStatus.Delivered.toString(),
  ].includes(shipmentStatus);

export default isHelpTextNeeded;
