import defaultShipment from './constants/defaultShipment';
import getTrackingEvents from './helpers/getTrackingEvents';
import type { ITrackingResponse } from '@_types/type';

const trackingServiceMockData: { [propName: string]: ITrackingResponse } = {
  MOCK_92612903029846543475000000: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000000',
      shipmentStatus: 'Ordered',
      hasEstimatedDeliveryDateChanged: true,
      estimatedDeliveryDate: '2023-06-04',
    },
  },
  MOCK_92612903029846543475000001: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000001',
      hasEstimatedDeliveryDateChanged: true,
      estimatedDeliveryDate: '2023-06-06',
    },
  },
  MOCK_92612903029846543475000002: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000002',
      carrierCode: 'DHL',
      carrierTrackingURL: 'https://www.dhl.com/',
      trackingEvents: getTrackingEvents(1),
    },
  },
  MOCK_92612903029846543475000003: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000003',
      estimatedDeliveryDate: null,
      carrierCode: 'FEDEX',
      carrierTrackingURL: 'https://www.fedex.com/',
      trackingEvents: getTrackingEvents(3),
    },
  },
  MOCK_92612903029846543475000004: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000004',
      shipmentStatus: 'Out for Delivery',
      carrierCode: 'ONTRAC',
      carrierTrackingURL: 'https://www.ontrac.com/',
      trackingEvents: getTrackingEvents(4),
    },
  },
  MOCK_92612903029846543475000005: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000005',
      shipmentStatus: 'Delivery Exception',
      carrierCode: 'PANDION',
      carrierTrackingURL: 'https://tracking.pandionpro.com/',
      trackingEvents: getTrackingEvents(5),
    },
  },
  MOCK_92612903029846543475000006: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000006',
      shipmentStatus: 'Delivered',
      carrierCode: 'SMARTKARGO',
      carrierTrackingURL: 'https://masair.smartkargo.com/TrackFrame.aspx',
      trackingEvents: getTrackingEvents(7),
      estimatedDeliveryDate: null,
    },
  },
  MOCK_92612903029846543475000007: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000007',
      carrierCode: 'TFORCEFREIGHT',
      carrierTrackingURL: 'https://www.tforcefreight.com/',
      trackingEvents: getTrackingEvents(9),
    },
  },
  MOCK_92612903029846543475000008: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000008',
      carrierCode: 'UPS',
      carrierTrackingURL: 'https://www.ups.com/',
      trackingEvents: getTrackingEvents(11),
    },
  },
  MOCK_92612903029846543475000009: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000009',
      carrierCode: 'USPS',
      carrierTrackingURL: 'https://www.usps.com/',
      trackingEvents: getTrackingEvents(13),
    },
  },
  MOCK_92612903029846543475000010: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000010',
      carrierCode: 'other carrier',
      carrierTrackingURL: '',
      trackingEvents: getTrackingEvents(15),
    },
  },
  MOCK_926129030298465434750000011: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000004',
      shipmentStatus: 'Out for Delivery',
      carrierCode: 'ONTRAC',
      carrierTrackingURL: 'https://www.ontrac.com/',
      estimatedDeliveryDate: null,
      trackingEvents: getTrackingEvents(4),
    },
  },
  MOCK_926129030298465434750000012: {
    shipment: {
      ...defaultShipment,
      trackingID: 'MOCK_92612903029846543475000004',
      shipmentStatus: 'Ordered',
      carrierCode: 'ONTRAC',
      carrierTrackingURL: 'https://www.ontrac.com/',
      estimatedDeliveryDate: null,
      trackingEvents: getTrackingEvents(4),
    },
  },
  MOCK_92612903029846543475000013: {
    shipment: null,
  },
};

export const getTrackingServiceMockDataKeys = () =>
  Object.keys(trackingServiceMockData) as Array<
    keyof typeof trackingServiceMockData
  >;

export default trackingServiceMockData;
