import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const ChevronRight: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="15"
    height="23"
    viewBox="0 0 15 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.39765L1.42169 0L14.375 11.3238L1.42313 22.6334L0 21.2358L11.5604 11.2941L0 1.39765Z"
      fill="#212427"
    />
  </svg>
);

export default withIcon(ChevronRight);
