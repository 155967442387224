function validateRequiredProps(
  componentName: string,
  // eslint-disable-next-line
  props: any,
  requiredProps: string[],
): string[] {
  const isMissingProps: string[] = [];
  /** Validate the required props */
  requiredProps.forEach((requiredProp) => {
    if (!props[requiredProp]) {
      isMissingProps.push(requiredProp);
    }
  });
  const errors = [];

  /** Throw error if missing props were found */
  if (isMissingProps.length) {
    errors.push(
      `[${componentName}]: Missing required props: ${isMissingProps.join(
        ', ',
      )}`,
    );
  }

  return errors;
}

// eslint-disable-next-line
function checkArray(obj: any, key: string, expectedType: any): string[] {
  const errors: string[] = [];
  if (!Array.isArray(obj[key])) {
    errors.push(`${key} should be an array.`);
  } else {
    // eslint-disable-next-line
    for (const item of obj[key]) {
      // eslint-disable-next-line
      errors.push(...validateSchedule(item, expectedType[0]));
    }
  }
  return errors;
}

// eslint-disable-next-line
function checkObject(obj: any, key: string, expectedType: any): string[] {
  const errors: string[] = [];
  if (typeof obj[key] === 'object') {
    // eslint-disable-next-line
    errors.push(...validateSchedule(obj[key], expectedType));
  } else if (typeof obj[key] !== 'object') {
    errors.push(`${key} should be type object.`);
  }
  return errors;
}

function checkType(
  actualType: string,
  key: string,
  expectedType: string,
): string[] {
  const errors: string[] = [];
  if (expectedType && expectedType !== actualType)
    errors.push(
      `${key} should be type ${expectedType}, but ${actualType} was detected.`,
    );
  return errors;
}

// eslint-disable-next-line
function checkComplexType(obj: any, key: string, expectedType: any): string[] {
  const errors: string[] = [];
  if (Array.isArray(expectedType)) {
    errors.push(...checkArray(obj, key, expectedType));
  } else {
    errors.push(...checkObject(obj, key, expectedType));
  }
  return errors;
}

// This function requires an schedule (object) to know how is the interface
// eslint-disable-next-line
function validateSchedule(obj: any, schedule: any): string[] {
  const errors = [];
  // eslint-disable-next-line
  for (const key in obj) {
    const expectedType = schedule[key];
    const actualType = typeof obj[key];

    if (typeof expectedType === 'object') {
      errors.push(...checkComplexType(obj, key, expectedType));
    } else {
      errors.push(...checkType(actualType, key, expectedType));
    }
  }
  return errors;
}

const propValidation = (
  componentName: string,
  // eslint-disable-next-line
  props: any,
  requiredProps: string[],
  schema: object,
): boolean => {
  const errors: string[] = validateRequiredProps(
    componentName,
    props,
    requiredProps,
  );
  errors.push(...validateSchedule(props, schema));
  // eslint-disable-next-line
  errors.forEach((e) => console.error(e));

  return errors.length === 0;
};

export default propValidation;
