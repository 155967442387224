import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Twitter: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1273 7.1C24.5455 8 23.8182 8.75 22.9455 9.5C22.9455 9.65 22.9455 9.8 22.9455 9.95C22.9455 16.85 17.8545 23.9 9.41818 23.9C6.8 23.9 4.32727 23.15 2.14545 21.65C2 21.5 2 21.2 2 21.2C2 21.2 2.29091 20.9 2.43636 20.9C2.72727 20.9 3.16364 20.9 3.45455 20.9C5.05455 20.9 6.65455 20.45 8.10909 19.55C6.36364 18.95 5.05455 17.75 4.47273 15.95C4.47273 15.8 4.47273 15.65 4.61818 15.5C4.76364 15.5 4.90909 15.35 5.05455 15.5C5.2 15.5 5.2 15.5 5.34545 15.5C3.89091 14.6 3.01818 12.95 3.01818 11.15C2.87273 10.85 3.01818 10.85 3.16364 10.7C3.30909 10.7 3.45455 10.7 3.45455 10.85C3.74545 11 3.89091 11 4.03636 11.15C3.30909 10.25 3.01818 9.05 3.01818 7.85C3.01818 6.95 3.30909 6.05 3.74545 5.3C3.74545 5.15 3.74545 5 3.89091 5C4.03636 5 4.18182 5 4.18182 5.15C6.36364 7.85 9.41818 9.65 12.9091 9.95C12.9091 9.8 12.9091 9.5 12.9091 9.35C12.9091 6.5 15.2364 4.25 18 4.25C19.3091 4.25 20.6182 4.85 21.4909 5.75C22.3636 5.6 23.2364 5.15 24.1091 4.7C24.2545 4.55 24.4 4.55 24.5455 4.7C24.6909 4.85 24.6909 5 24.6909 5C24.5455 5.6 24.1091 6.2 23.8182 6.8C24.1091 6.65 24.5455 6.5 24.8364 6.5C24.9818 6.5 25.1273 6.5 25.2727 6.65C25.4182 6.8 25.2727 6.8 25.1273 7.1Z"
      fill="#6E6E6E"
    />
  </svg>
);

export default withIcon(Twitter);
