interface ICarouselSlidesBreakpoints {
  [key: string]: number;
}

const carouselSlidesBreakpoints: ICarouselSlidesBreakpoints = {
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
};

export default carouselSlidesBreakpoints;
