import userAgentIdentifiers from '@src/constants/userAgentIdentifiers';

const isMobileApplication = () => {
  const { userAgent } = window.navigator;
  const identifiers = Object.values(userAgentIdentifiers);

  return identifiers.some((identifier) => userAgent.includes(identifier));
};

export default isMobileApplication;
