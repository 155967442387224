import React from 'react';
import createAttributes, {
  hyperlinkType,
} from '@shared/components/Hyperlink/utilities/CreateAttributes';
import type {
  IHyperlinkProps,
  IHyperlinkVariantTypes,
} from '@shared/components/Hyperlink/IHyperlink';
import type { FC } from 'react';

export interface HyperlinkProps extends IHyperlinkProps {
  /**
   * Is the part that will be visible to the reader. For HyperlinkIcon, this is used to inform screen readers
   */
  label?: string;
  /**
   * Indicates style of hyperlink
   */
  variant?: IHyperlinkVariantTypes | string;
  /**
   * Children used to render the custom component in a hyperlink
   */
  children?: React.ReactNode;
}

export const Hyperlink: FC<HyperlinkProps> = (props) => {
  const { label, children } = props;
  const attributes = createAttributes(props, hyperlinkType.TEXT);

  if (!children && !label) {
    return null;
  }

  return <a {...attributes}>{children ? children : <span>{label}</span>}</a>;
};

Hyperlink.displayName = 'Hyperlink';

export default Hyperlink;
