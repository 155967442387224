import type { IScreenBreakpoints } from '@_types/type';

const screenBreakpoints: IScreenBreakpoints = {
  sm: 430,
  tablet: 743,
  md: 1023,
  lg: 1260,
};

export default screenBreakpoints;
