export const STAGING_PROXY_BASE_URL =
  'https://uki98td1ll.execute-api.us-east-1.amazonaws.com/api';

export const PRODUCTION_PROXY_BASE_URL =
  'https://i502ijswab.execute-api.us-east-1.amazonaws.com/api';

export default {
  STAGING_PROXY_BASE_URL,
  PRODUCTION_PROXY_BASE_URL,
};
