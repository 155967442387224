import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Pinterest: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2C7.4 2 2 7.4 2 14C2 19.1 5.15 23.45 9.65 25.1C9.5 24.2 9.5 22.7 9.65 21.65C9.8 20.75 11 15.65 11 15.65C11 15.65 10.7 15.05 10.7 14C10.7 12.35 11.6 11.15 12.8 11.15C13.85 11.15 14.3 11.9 14.3 12.8C14.3 13.85 13.7 15.35 13.25 16.85C12.95 18.05 13.85 18.95 15.05 18.95C17.15 18.95 18.8 16.7 18.8 13.55C18.8 10.7 16.7 8.75 13.85 8.75C10.4 8.75 8.45 11.3 8.45 14C8.45 15.05 8.9 16.1 9.35 16.7C9.5 16.55 9.5 16.7 9.5 16.85C9.35 17.3 9.2 18.05 9.2 18.2C9.2 18.35 9.05 18.5 8.75 18.35C7.25 17.6 6.35 15.5 6.35 13.7C6.35 9.95 9.05 6.5 14.3 6.5C18.5 6.5 21.65 9.5 21.65 13.4C21.65 17.45 19.1 20.9 15.5 20.9C14.3 20.9 13.1 20.3 12.8 19.55C12.8 19.55 12.2 21.8 12.05 22.4C11.75 23.45 11 24.8 10.55 25.55C11.6 25.7 12.8 26 14 26C20.6 26 26 20.6 26 14C26 7.4 20.6 2 14 2Z"
      fill="#6E6E6E"
    />
  </svg>
);

export default withIcon(Pinterest);
