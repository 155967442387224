import ShipmentItems from '@components/ShipmentItems';
import TrackingHistory from '@components/TrackingHistory';
import TrackingWidget from '@components/TrackingWidget';
import { TrackingContext } from '@context/TrackingContext';
import React from 'react';
import '@components/ShipmentWrapper/ShipmentWrapper.css';

const ShipmentWrapper = () => {
  const trackingResponse = React.useContext(TrackingContext)?.trackingResponse;
  const shipment = trackingResponse?.shipment;
  const estimatedDeliveryDate = shipment?.estimatedDeliveryDate;

  return (
    <div className="shipment-wrapper">
      <div className="tracking-wrapper">
        <TrackingWidget />
        {shipment && estimatedDeliveryDate && <TrackingHistory />}
      </div>
      <div className="shipment-items-wrapper">
        <ShipmentItems />
      </div>
    </div>
  );
};

export default ShipmentWrapper;
