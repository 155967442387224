import classNames from 'classnames';
import type {
  IBuiltTextAttribute,
  ITextAttribute,
} from '@typography/ITypography';

const getStyleClasses: (
  props: ITextAttribute,
  prefix: string,
) => IBuiltTextAttribute = (
  { variant, color, fontFamily, align, transform, weight, className },
  prefix: string,
) => ({
  className: classNames(prefix, {
    [`${prefix}-${variant}`]: variant,
    [`${prefix}-color-${color}`]: color,
    [`${prefix}-font-${fontFamily}`]: fontFamily,
    [`${prefix}-align-${align}`]: align,
    [`${prefix}-transform-${transform}`]: transform,
    [`${prefix}-weight-${weight}`]: weight,
    [`${className}`]: className,
  }),
});
const createAttributes: (props: ITextAttribute) => IBuiltTextAttribute = (
  props: ITextAttribute,
) => getStyleClasses(props, 'typography');
export default createAttributes;
