import TRACK_NUMBER_PARAM from '@src/constants/queryParams';
import { getQueryString } from '@utils/url';
import type { ITrackingResponse } from '@_types/type';

export const isCurrentShipment = (trackingID: string): boolean => {
  const currentShipmentID = getQueryString(TRACK_NUMBER_PARAM);

  return currentShipmentID === trackingID;
};

export const hasAtLeastTwoShipments = (
  otherShipments: ITrackingResponse[],
): boolean => otherShipments.length >= 2;

export default { isCurrentShipment, hasAtLeastTwoShipments };
