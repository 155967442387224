import createAttributes from '@typography/utilities/CreateAttributes';
import TemplateOptions from '@typography/utilities/TemplateOptions';
import React from 'react';
import type { ITypographyProps } from '@typography/ITypography';

const Typography: React.FC<ITypographyProps> = (props: ITypographyProps) => {
  const {
    variant,
    color,
    fontFamily,
    align,
    transform,
    weight,
    className,
    children,
    component = 'p',
  } = props;
  const attributes = createAttributes({
    variant,
    color,
    fontFamily,
    align,
    transform,
    weight,
    className,
  });
  const TextComponent = TemplateOptions[component];

  return <TextComponent {...attributes}>{children}</TextComponent>;
};

export default Typography;
