import React from 'react';
import './CheckIcon.css';
import { getStyleByStage } from './helpers';

const CheckIcon = (props: { isCurrentStage: boolean }) => {
  const { isCurrentStage } = props;
  const { fillColor, strokeColor } = getStyleByStage(isCurrentStage);
  return (
    <svg
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      className={`check-mark-svg ${fillColor} ${strokeColor}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5001 1.03711C7.34115 1.03711 1.53711 6.84115 1.53711 14.0001C1.53711 21.159 7.34115 26.963 14.5001 26.963C21.659 26.963 27.463 21.159 27.463 14.0001C27.463 6.84115 21.659 1.03711 14.5001 1.03711Z"
      />
      <g className="check-mark-surface">
        <path d="M7.41138 15.0518L11.1707 18.8111" strokeLinecap="square" />
        <path d="M11.1709 18.8113L20.7266 9.26074" strokeLinecap="square" />
      </g>
    </svg>
  );
};
export default CheckIcon;
