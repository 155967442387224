import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

interface IRowProps {
  className?: string;
  children: ReactNode;
}

const Row = ({ className, children, ...restProps }: IRowProps) => {
  const rowClasses = classNames('grid', className);

  return (
    <div className={rowClasses} {...restProps}>
      {children}
    </div>
  );
};

export default Row;
