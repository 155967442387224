import React from 'react';
import './NumberIcon.css';
import iconNumbers from './constants/iconNumbers';

const NumberIcon = (props: { stageIndex: number }) => {
  const { stageIndex } = props;
  const stageNumber = stageIndex + 1;
  const iconNumber = iconNumbers[stageNumber] ?? null;
  if (!iconNumber) {
    return null;
  }
  return <div className="bubble-container">{iconNumber}</div>;
};
export default NumberIcon;
