import * as React from 'react';
import withIcon from '../withIcon';
import type { IconProps } from '../IIcon';

const App: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="19"
    height="30"
    viewBox="0 0 19 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6552 0C16.9841 0 18.8889 1.90983 18.8889 4.2435V25.7565C18.8889 28.0902 16.9841 30 14.6552 30H4.23372C1.90477 30 0 28.0902 0 25.7565V4.2435C0 1.90983 1.90477 0 4.23372 0H14.6552ZM17.7769 24.9993H1.11067L1.11111 25.7565C1.11111 27.399 2.39399 28.7599 4.00191 28.8802L4.23372 28.8889H14.6552C16.3698 28.8889 17.7778 27.4772 17.7778 25.7565L17.7769 24.9993ZM9.44444 25.5556C10.0581 25.5556 10.5556 26.053 10.5556 26.6667C10.5556 27.2803 10.0581 27.7778 9.44444 27.7778C8.83082 27.7778 8.33333 27.2803 8.33333 26.6667C8.33333 26.053 8.83082 25.5556 9.44444 25.5556ZM17.7769 4.99956H1.11067V23.8887H17.7769V4.99956ZM14.6552 1.11111H4.23372C2.63837 1.11111 1.30851 2.33322 1.13119 3.88922H17.7577C17.5804 2.33322 16.2505 1.11111 14.6552 1.11111Z"
      fill="black"
    />
  </svg>
);

export default withIcon(App);
