import trackingServiceMockData, {
  getTrackingServiceMockDataKeys,
} from '@services/mockData/trackingService.mockData';
import type { ITrackingResponse, IGetShipmentsProps } from '@_types/type';

const getOtherShipments = (
  tracking: IGetShipmentsProps,
): ITrackingResponse[] | undefined => {
  const { trackingIDs } = tracking;
  const otherShipments: ITrackingResponse[] = [];
  const trackingServiceMockDataKeys = getTrackingServiceMockDataKeys();
  trackingIDs.forEach((trackingID: string) => {
    if (trackingServiceMockDataKeys.includes(trackingID)) {
      otherShipments.push(trackingServiceMockData[trackingID]);
    }
  });
  return otherShipments.length ? otherShipments : undefined;
};

export default getOtherShipments;
