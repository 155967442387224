import { getHtmlCodeSymbolText } from '@utils/htmlCodePaser';

export const getPriceText = (price: string, quantity: number) => {
  const dollarSign = getHtmlCodeSymbolText('DOLLAR_SIGN');
  if (quantity > 1) {
    const multiplicationSign = getHtmlCodeSymbolText('MULTIPLICATION_SIGN');
    return `${dollarSign}${price} ${multiplicationSign} ${quantity}`;
  }
  return `${dollarSign}${price}`;
};

export default {
  getPriceText,
};
