import isMobileApplication from '@utils/isMobileApplication';
import FooterApp from '@shared/components/Footer/FooterApps';
import FooterColumns from '@shared/components/Footer/FooterColumns';
import FooterLegal from '@shared/components/Footer/FooterLegal';
import FooterSocial from '@shared/components/Footer/FooterSocial';
import Container from '@shared/components/Footer/layout/Container';
import Row from '@shared/components/Footer/layout/Row';
import FooterContext from '@shared/context/FooterContext';
import cx from 'classnames';
import React from 'react';

export interface IFooterProps {
  showLoyalty: boolean;
}

const Footer = ({ showLoyalty }: IFooterProps) => {
  if (isMobileApplication()) {
    return null;
  }
  return (
    <FooterContext.Provider value={{ showLoyalty }}>
      <footer
        id="footercontent"
        className={cx(
          'block',
          'relative',
          'wc-so5-pwa',
          'adobelaunch__footernavigation',
        )}
      >
        {/* <BackToTop /> */}
        <div className="footer-wrapper">
          <Container className="footer-container">
            <Row className={cx('footer-row-1', 'lg:grid-cols-4')}>
              <FooterColumns />
            </Row>
            <Row className="footer-row-2">
              <FooterSocial />
            </Row>
            <Row
              className={cx('footer-row-3', 'lg:grid-cols-2', 'items-center')}
            >
              <FooterLegal />
              <FooterApp />
            </Row>
          </Container>
        </div>
      </footer>
    </FooterContext.Provider>
  );
};

export default Footer;
