import isUpdatedDelivery from '@components/TrackingWidget/TrackingWidgetDate/helpers/isUpdatedDelivery';
import ShipmentStatus from '@components/enums';
import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import { getHtmlCodeSymbolText } from '@utils/htmlCodePaser';
import React from 'react';
import type { ITrackingResponse, Nullable } from '@_types/type';
import '../Summary.css';

const PACKAGE_ON_WAY = 'Your package is on its way.';
const PACKAGE_ON_WAY_LATER =
  'Your package is on its way but will arrive later than originally expected.';
const OUT_FOR_DELIVERY = 'Your package is out for delivery.';
const PACKAGE_DELIVERED = 'Your package has been delivered.';
const UNAVAILABLE_MESSAGE =
  'Please check back later or refer to your shipping confirmation email.';
const AWAITING_CARRIER_PICKUP_MESSAGE =
  'We received your order. Please check back later for the estimated delivery date.';

const getCarrierInfoText = (
  carrierTrackingURL: Nullable<string>,
): JSX.Element => {
  const spaceSymbolText = getHtmlCodeSymbolText('SPACE_SIGN');

  return (
    <>
      Please{spaceSymbolText}
      <Hyperlink
        className="shipment-summary-link"
        href={carrierTrackingURL || ''}
        target={ANCHOR_TARGET}
        rel="noreferrer"
      >
        check carrier details
      </Hyperlink>
      {spaceSymbolText}
      for more information.
    </>
  );
};

const getNoEDDText = (carrierTrackingURL: Nullable<string>): JSX.Element => {
  const spaceSymbolText = getHtmlCodeSymbolText('SPACE_SIGN');

  return (
    <>
      {PACKAGE_ON_WAY}
      {spaceSymbolText}
      {getCarrierInfoText(carrierTrackingURL)}
    </>
  );
};

const getShipmentSummary = (
  trackingResponse: ITrackingResponse,
): string | JSX.Element => {
  const { shipment } = trackingResponse;
  if (!shipment) {
    return UNAVAILABLE_MESSAGE;
  }

  const shipmentStatus = shipment?.shipmentStatus;
  const carrierTrackingURL = shipment?.carrierTrackingURL || '';
  const estimatedDeliveryDate = shipment?.estimatedDeliveryDate;
  if (
    !estimatedDeliveryDate &&
    shipmentStatus !== ShipmentStatus.Delivered &&
    shipmentStatus !== ShipmentStatus.DeliveryException &&
    shipmentStatus !== ShipmentStatus.OutForDelivery
  ) {
    return getNoEDDText(carrierTrackingURL);
  }
  if (shipmentStatus === ShipmentStatus.Ordered) {
    return AWAITING_CARRIER_PICKUP_MESSAGE;
  }
  if (shipmentStatus === ShipmentStatus.Shipped) {
    return isUpdatedDelivery(shipment) ? PACKAGE_ON_WAY_LATER : PACKAGE_ON_WAY;
  }
  if (shipmentStatus === ShipmentStatus.OutForDelivery) {
    return OUT_FOR_DELIVERY;
  }
  if (shipmentStatus === ShipmentStatus.DeliveryException) {
    return getCarrierInfoText(carrierTrackingURL);
  }
  if (shipmentStatus === ShipmentStatus.Delivered) {
    return PACKAGE_DELIVERED;
  }

  return '';
};

export default getShipmentSummary;
