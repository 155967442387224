import withIcon from '@icon/withIcon';
import * as React from 'react';
import type { IconProps } from '@icon/IIcon';

const Circle: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0342 3C7.94046 3 3 7.94046 3 14.0342C3 20.128 7.94046 25.0684 14.0342 25.0684C20.128 25.0684 25.0684 20.128 25.0684 14.0342C25.0684 7.94046 20.128 3 14.0342 3Z"
      fill="#444444"
      stroke="#444444"
    />
    <path
      d="M8 14.9295L11.2 18.1295"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M11.2002 18.1295L19.3341 10"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export default withIcon(Circle);
