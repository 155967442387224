import Carousel from '@carousel/Carousel';
import carouselSlidesBreakpoints from '@components/ShipmentItems/constants/carouselBreakpoints';
import {
  addResizeListener,
  removeResizeListener,
} from '@shared/utilities/resizeListener';
import screenBreakpoints from '@src/constants/screenBreakpoints';
import React, { useState, useEffect } from 'react';
import getCurrentBreakpoint from './helpers';
import type { IShipmentItemsCarouselProps } from '@components/ShipmentItems/IShipmentItems';
import './ShipmentItemsCarousel.css';

const ShipmentItemsCarousel = (props: IShipmentItemsCarouselProps) => {
  const [isCarousel, setIsCarousel] = useState(false);
  const { sm } = screenBreakpoints;
  const { children } = props;

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= sm;
      const currentBreakpoint = getCurrentBreakpoint(window.innerWidth);

      const isCarouselRendered = isMobile
        ? false
        : React.Children.count(children) >
          carouselSlidesBreakpoints[currentBreakpoint];

      setIsCarousel(isCarouselRendered);
    };

    handleResize();
    addResizeListener(handleResize);
    return () => removeResizeListener(handleResize);
  }, [sm, children]);

  return (
    <div className="shipment-items-carousel">
      {isCarousel ? <Carousel>{children}</Carousel> : children}
    </div>
  );
};

export default ShipmentItemsCarousel;
