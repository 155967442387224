import OrderNumber from '@components/OrderNumber';
import OtherShipments from '@components/OtherShipments';
import ShipmentWrapper from '@components/ShipmentWrapper';
import { OrderContextProvider } from '@context/OrderContext';
import { ShipmentsContextProvider } from '@context/OtherShipmentsContext';
import { TrackingContextProvider } from '@context/TrackingContext';
import React from 'react';
import './OrderWrapper.css';

const OrderWrapper = () => (
  <div className="order-wrapper">
    <OrderContextProvider>
      <OrderNumber />
      <TrackingContextProvider>
        <ShipmentWrapper />
      </TrackingContextProvider>
      <ShipmentsContextProvider>
        <OtherShipments />
      </ShipmentsContextProvider>
    </OrderContextProvider>
  </div>
);

export default OrderWrapper;
