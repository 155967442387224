import Typography from '@typography/Typography';
import React from 'react';
import type { ITrackingEvent } from '@_types/type';
import './TrackingHistoryEventDescription.css';

interface ITrackingHistoryEventDescriptionProps {
  trackingEvent: ITrackingEvent;
}

const TrackingHistoryEventDescription = (
  props: ITrackingHistoryEventDescriptionProps,
) => {
  const { trackingEvent } = props;

  return (
    <div className="tracking-event-description">
      <Typography fontFamily="centra-book">
        {trackingEvent.statusDescription}
      </Typography>
      <Typography
        component="span"
        transform="uppercase"
        fontFamily="centra-book"
      >
        {trackingEvent.eventCity}
        {trackingEvent.eventState
          ? `, ${trackingEvent.eventState} ${trackingEvent.eventZIPCode}`
          : ''}
      </Typography>
    </div>
  );
};

export default TrackingHistoryEventDescription;
