import isUpdatedDelivery from '@components/TrackingWidget/TrackingWidgetDate/helpers/isUpdatedDelivery';
import ShipmentStatus from '@components/enums';
import type { ITrackingResponse } from '@_types/type';

const UPDATED_DELIVERY = 'Updated Delivery';
const ESTIMATED_DELIVERY = 'Estimated Delivery';
const SHIPMENT_ERROR = 'Shipment Error';
const DELIVERED = 'Delivered';

const getShipmentStatus = (trackingResponse: ITrackingResponse): string => {
  const shipmentStatus = trackingResponse.shipment?.shipmentStatus;

  switch (shipmentStatus) {
    case ShipmentStatus.Shipped:
      if (isUpdatedDelivery(trackingResponse.shipment)) {
        return UPDATED_DELIVERY;
      }
      return ESTIMATED_DELIVERY;
    case ShipmentStatus.OutForDelivery:
      return ESTIMATED_DELIVERY;
    case ShipmentStatus.DeliveryException:
      return SHIPMENT_ERROR;
    case ShipmentStatus.Delivered:
      return DELIVERED;
    default:
      return ESTIMATED_DELIVERY;
  }
};

export default getShipmentStatus;
