import Hyperlink from '@shared/components/Hyperlink/Hyperlink';
import Typography from '@shared/components/Typography/Typography';
import { ANCHOR_TARGET } from '@src/constants/anchorTargets';
import React from 'react';
import './TrackShipment.css';
import type { ITrackShipmentProps } from '@components/OtherShipments/IOtherShipments';

const TrackShipment = (props: ITrackShipmentProps) => {
  const { trackingID } = props;

  return (
    <Hyperlink
      href={`?tracknumber=${trackingID}`}
      className="other-shipments-track-button btn-secondary"
      target={ANCHOR_TARGET}
    >
      <Typography
        fontFamily="centra-medium"
        component="span"
        className="other-shipments-track-button-text"
        align="center"
      >
        Track package
      </Typography>
    </Hyperlink>
  );
};

export default TrackShipment;
