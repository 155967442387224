import { ButtonClose } from '@button/ButtonClose';
import createAttributes from '@dialog/utilities/CreateAttributes';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import cx from 'classnames';
import React from 'react';
import type { IDialogProps } from '@dialog/IDialog';
import type { FC } from 'react';

const Dialog: FC<IDialogProps> = ({
  variant,
  id,
  titleElement,
  description,
  children,
  closeButtonElement,
  setShowDialog,
  showDialog,
  showCloseButton = true,
  transitionClassName = '',
  panelClassName = '',
  closeButtonClassName = '',
  ...restProps
}) => {
  const att = createAttributes(variant, panelClassName, transitionClassName);
  // TODO: update to use button components

  const toggleOpen = () => {
    setShowDialog(!showDialog);
  };

  return (
    <div {...restProps}>
      <Transition show={showDialog}>
        <HeadlessDialog
          open={showDialog}
          onClose={toggleOpen}
          {...att.dialogAttributes}
        >
          <Transition.Child {...att.transitionFadeAttributes}>
            <HeadlessDialog.Overlay {...att.dialogOverlayAttributes} />
          </Transition.Child>
          <Transition.Child {...att.transitionAttributes}>
            <HeadlessDialog.Panel {...att.panelAttributes}>
              <HeadlessDialog.Title as="div">
                {titleElement}
              </HeadlessDialog.Title>
              <HeadlessDialog.Description>
                {description}
              </HeadlessDialog.Description>
              {children}
              {showCloseButton &&
                (closeButtonElement ? (
                  <button
                    type="button"
                    onClick={toggleOpen}
                    className={cx(
                      'absolute',
                      'top-0',
                      'right-0',
                      'z-10',
                      closeButtonClassName,
                    )}
                  >
                    {closeButtonElement}
                  </button>
                ) : (
                  <ButtonClose
                    onClick={toggleOpen}
                    className={cx(
                      'absolute',
                      'top-0',
                      'right-0',
                      'z-10',
                      closeButtonClassName,
                    )}
                  />
                ))}
            </HeadlessDialog.Panel>
          </Transition.Child>
        </HeadlessDialog>
      </Transition>
    </div>
  );
};

Dialog.displayName = 'Dialog';

export default Dialog;
