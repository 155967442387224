import { OrderContext } from '@context/OrderContext';
import getTrackingIDs from '@context/helpers/otherShipmentsHelper';
import getShipments from '@services/shipmentsService';
import isEmptyObject from '@utils/isEmptyObject';
import React, { createContext, useState, useEffect, useMemo } from 'react';
import type {
  IShipmentsContextProps,
  ITrackingResponse,
  Nullable,
} from '@_types/type';

interface IShipmentsContextProviderProps {
  children: React.ReactNode;
}

export const ShipmentsContext =
  createContext<Nullable<IShipmentsContextProps>>(null);

export const ShipmentsContextProvider = ({
  children,
}: React.PropsWithChildren<IShipmentsContextProviderProps>) => {
  const [otherShipmentsResponse, setOtherShipmentsResponse] =
    useState<Nullable<ITrackingResponse[]>>(null);
  const orderResponse = React.useContext(OrderContext)?.orderResponse;
  const isOrderLoading = React.useContext(OrderContext)?.isOrderLoading;
  const [isOtherShipmentsLoading, setIsOtherShipmentsLoading] = useState(true);

  useEffect(() => {
    const callAPI = async () => {
      try {
        const otherShipmentsIDs = {
          trackingIDs: getTrackingIDs(orderResponse?.shipments ?? []),
        };
        const shipments = await getShipments(otherShipmentsIDs);
        if (!shipments || isEmptyObject(shipments)) {
          throw new Error('Shipments not found');
        }
        setOtherShipmentsResponse(shipments);
      } catch {
        setOtherShipmentsResponse(null);
      }
      setIsOtherShipmentsLoading(false);
    };
    if (orderResponse) {
      callAPI();
    } else if (!isOrderLoading) {
      setIsOtherShipmentsLoading(false);
    }
  }, [isOrderLoading, orderResponse]);

  const contextValue = useMemo(
    () => ({
      otherShipmentsResponse,
      isOtherShipmentsLoading,
    }),
    [isOtherShipmentsLoading, otherShipmentsResponse],
  );

  return (
    <ShipmentsContext.Provider value={contextValue}>
      {children}
    </ShipmentsContext.Provider>
  );
};

export default { ShipmentsContext, ShipmentsContextProvider };
