import cx from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

interface IContainerProps {
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  className?: string;
  children: ReactNode;
}

const Container = ({
  className,
  children,
  sm,
  md,
  lg,
  xl,
  ...restProps
}: IContainerProps) => {
  const containerClasses = cx('container', 'mx-auto', 'p-0', className, {
    'max-w-screen-sm': sm,
    'max-w-screen-md': md,
    'max-w-screen-lg': lg,
    'max-w-screen-xl': xl,
  });

  return (
    <div className={containerClasses} {...restProps}>
      {children}
    </div>
  );
};

export default Container;
