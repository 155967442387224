import Iframe from '@components/EinsteinIframe/Iframe/Iframe';
import { STAGING_URLS, PRODUCTION_URLS } from '@src/constants/environmentUrls';
import React from 'react';
import {
  PRODUCTION_SFCC_EINSTEIN_URL,
  STAGING_SFCC_EINSTEIN_URL,
} from './constants';

const getURL = () => {
  const currentLocationURL = window.location.href;

  if (STAGING_URLS.some((url: string) => currentLocationURL.includes(url))) {
    return STAGING_SFCC_EINSTEIN_URL;
  }
  if (PRODUCTION_URLS.some((url: string) => currentLocationURL.includes(url))) {
    return PRODUCTION_SFCC_EINSTEIN_URL;
  }

  return STAGING_SFCC_EINSTEIN_URL;
};

const EinsteinIframe = () => <Iframe url={getURL()} title="Einstein" />;

export default EinsteinIframe;
