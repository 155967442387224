import TrackingHistoryDialogContent from '@components/TrackingHistory/TrackingHistoryDialogContent';
import TrackingHistoryTitle from '@components/TrackingHistory/TrackingHistoryTitle';
import Dialog from '@dialog/Dialog';
import screenBreakpoints from '@src/constants/screenBreakpoints';
import Typography from '@typography/Typography';
import React, { useState } from 'react';
import './TrackingHistory.css';

const TrackingHistory = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dialogVariant =
    window.innerWidth > screenBreakpoints.tablet ? 'center' : 'bottom';
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="tracking-dialog-button"
      >
        <Typography
          fontFamily="centra-book"
          align="right"
          className="tracking-dialog-show-text"
        >
          Track shipment status
        </Typography>
      </button>
      <Dialog
        id="trackingHistoryDialog"
        variant={dialogVariant}
        transitionClassName="tracking-dialog-transition-wrapper"
        panelClassName="tracking-dialog-content-wrapper"
        showDialog={isOpen}
        setShowDialog={setIsOpen}
        titleElement={<TrackingHistoryTitle />}
        closeButtonClassName="tracking-dialog-close-button"
      >
        <TrackingHistoryDialogContent />
      </Dialog>
    </>
  );
};

export default TrackingHistory;
