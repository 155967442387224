import propValidation from '@shared/utilities/PropValidation';
import { useState, useEffect } from 'react';

const usePropValidation = (
  name: string,
  props: object,
  requiredProps: string[],
  schema: object,
) => {
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    setShouldRender(propValidation(name, props, requiredProps, schema));
    // eslint-disable-next-line
  }, []);

  return shouldRender;
};

export default usePropValidation;
