import type { ITrackingShipment, Nullable } from '@_types/type';

const isUpdatedDelivery = (shipment: Nullable<ITrackingShipment>): boolean => {
  const { originalEstimatedDeliveryDate, estimatedDeliveryDate } =
    shipment || {};

  if (!(originalEstimatedDeliveryDate && estimatedDeliveryDate)) {
    return false;
  }
  return (
    new Date(originalEstimatedDeliveryDate) < new Date(estimatedDeliveryDate)
  );
};

export default isUpdatedDelivery;
