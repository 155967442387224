import ProductItem from '@components/ShipmentItems/ProductItem';
import Skeleton from '@components/ShipmentItems/Skeleton';
import { OrderContext } from '@context/OrderContext';
import TRACK_NUMBER_PARAM from '@src/constants/queryParams';
import Typography from '@typography/Typography';
import utilsUrl from '@utils/url';
import React from 'react';
import '@components/ShipmentItems/ShipmentItems.css';
import ShipmentItemsCarousel from './ShipmentItemsCarousel/ShipmentItemsCarousel';

const ShipmentItems = () => {
  const orderResponse = React.useContext(OrderContext)?.orderResponse;
  const isOrderLoading = React.useContext(OrderContext)?.isOrderLoading;
  const trackNumber = utilsUrl.getQueryString(TRACK_NUMBER_PARAM);
  if (isOrderLoading) {
    return <Skeleton />;
  }
  if (!orderResponse) {
    return null;
  }
  const { shipments } = orderResponse;

  const mainShipment = shipments.find(
    (shipment) => shipment.trackingNumber === trackNumber,
  );
  const mainShipmentProducts = mainShipment?.products;
  const mainShipmentProductsCount = mainShipmentProducts?.length;

  return (
    <div className="shipment-items-container">
      <Typography
        className="shipment-items-title"
        fontFamily="ogg"
        component="h1"
      >
        Items in this shipment
      </Typography>
      {mainShipmentProductsCount && (
        <ShipmentItemsCarousel>
          {mainShipmentProducts.map((mainShipmentProduct) => (
            <ProductItem
              key={mainShipmentProduct.slugUrl}
              {...mainShipmentProduct}
            />
          ))}
        </ShipmentItemsCarousel>
      )}
    </div>
  );
};

export default ShipmentItems;
