import cx from 'classnames';

/** Defines the hyperlink type used to determine the associated attributes */
export const hyperlinkType = {
  TEXT: 'text',
  ICON: 'icon',
  ICON_TEXT: 'icon-text',
};

/**
 * Returns an object with hyperlink classes
 *
 * NOTE: We define CSS classes rather than use Tailwind utilities in the component.
 * This is to avoid having to using JavaScript to determine UI / UX.
 *
 * @param {...object} - spread object of hyperlinkProps
 * @param {string} prefix - class name prefix
 * @returns {object}
 */
const getStyleClasses = (
  // eslint-disable-next-line
  { variant, className, reverse }: any,
  hlinkType: string,
  prefix: string,
) => ({
  className: cx(prefix, {
    [`${prefix}-${variant}`]: variant && hlinkType === hyperlinkType.TEXT,
    [`${prefix}-${hyperlinkType.ICON}`]: hlinkType === hyperlinkType.ICON,
    [`${prefix}-${hyperlinkType.ICON_TEXT}`]:
      hlinkType === hyperlinkType.ICON_TEXT,
    [`${prefix}-${hyperlinkType.ICON_TEXT}-reverse`]:
      reverse && hlinkType === hyperlinkType.ICON_TEXT,
    [`${className}`]: className,
  }),
});

/**
 * Returns the hyperlink attributes
 *
 * @param props hyperlinkProps
 * @returns {object}
 */
// eslint-disable-next-line
const createAttributes = (props: any, hlinkType: string) => {
  // TODO: Find a better way to get the restProps
  const {
    label,
    variant,
    srLabel,
    reverse,
    width,
    height,
    className,
    icon,
    ...restProps
  } = props;
  return {
    ...getStyleClasses(props, hlinkType, 'hlink'),
    ...restProps,
  };
};

export default createAttributes;
