import type { IOrderResponse } from '@_types/type';

const ORDER_ONE = {
  orderNumber: '114345720',
  orderDate: '2023-01-13T21:39:34-05:00',
  shipments: [
    {
      trackingNumber: 'MOCK_92612903029846543475000000',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000001',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
      ],
    },
  ],
};
const ORDER_TWO = {
  orderNumber: '114345721',
  orderDate: '2023-01-13T21:39:34-05:00',
  shipments: [
    {
      trackingNumber: 'MOCK_92612903029846543475000002',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000003',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
      ],
    },
  ],
};
const ORDER_THREE = {
  orderNumber: '114345722',
  orderDate: '2023-01-13T21:39:34-05:00',
  shipments: [
    {
      trackingNumber: 'MOCK_92612903029846543475000004',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000005',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
        {
          id: '0400018956238',
          brand: 'LOUIS VUITTON',
          name: 'Zippy Leather Wallet',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956238',
          },
          price: '994.99',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/louis-vuitton-zippy-leather-wallet-0400018956238.html',
        },
      ],
    },
  ],
};
const ORDER_FOUR = {
  orderNumber: '114345723',
  orderDate: '2023-01-13T21:39:34-05:00',
  shipments: [
    {
      trackingNumber: 'MOCK_92612903029846543475000006',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
        {
          id: '0400018956238',
          brand: 'LOUIS VUITTON',
          name: 'Zippy Leather Wallet',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956238',
          },
          price: '994.99',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/louis-vuitton-zippy-leather-wallet-0400018956238.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000007',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
        {
          id: '0400018956238',
          brand: 'LOUIS VUITTON',
          name: 'Zippy Leather Wallet',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956238',
          },
          price: '994.99',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/louis-vuitton-zippy-leather-wallet-0400018956238.html',
        },
        {
          id: '0400018944149',
          brand: 'BOBBI BROWN',
          name: 'Blush',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018944149',
          },
          price: '35',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/bobbi-brown-blush-0400018944149.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000008',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
        {
          id: '0400018956238',
          brand: 'LOUIS VUITTON',
          name: 'Zippy Leather Wallet',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956238',
          },
          price: '994.99',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/louis-vuitton-zippy-leather-wallet-0400018956238.html',
        },
        {
          id: '0400018944149',
          brand: 'BOBBI BROWN',
          name: 'Blush',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018944149',
          },
          price: '35',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/bobbi-brown-blush-0400018944149.html',
        },
        {
          id: '0400014672817',
          brand: 'DOLCE&GABBANA',
          name: 'The One Eau de Toilette',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400014672817',
          },
          price: '134',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/dolce-gabbana-the-one-eau-de-toilette-0400014672817.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000009',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
        {
          id: '0400018956238',
          brand: 'LOUIS VUITTON',
          name: 'Zippy Leather Wallet',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956238',
          },
          price: '994.99',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/louis-vuitton-zippy-leather-wallet-0400018956238.html',
        },
        {
          id: '0400018944149',
          brand: 'BOBBI BROWN',
          name: 'Blush',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018944149',
          },
          price: '35',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/bobbi-brown-blush-0400018944149.html',
        },
        {
          id: '0400014672817',
          brand: 'DOLCE&GABBANA',
          name: 'The One Eau de Toilette',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400014672817',
          },
          price: '134',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/dolce-gabbana-the-one-eau-de-toilette-0400014672817.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000010',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
        {
          id: '0400018956238',
          brand: 'LOUIS VUITTON',
          name: 'Zippy Leather Wallet',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956238',
          },
          price: '994.99',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/louis-vuitton-zippy-leather-wallet-0400018956238.html',
        },
        {
          id: '0400018944149',
          brand: 'BOBBI BROWN',
          name: 'Blush',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018944149',
          },
          price: '35',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/bobbi-brown-blush-0400018944149.html',
        },
        {
          id: '0400014672817',
          brand: 'DOLCE&GABBANA',
          name: 'The One Eau de Toilette',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400014672817',
          },
          price: '134',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/dolce-gabbana-the-one-eau-de-toilette-0400014672817.html',
        },
      ],
    },
    {
      trackingNumber: 'MOCK_92612903029846543475000011',
      products: [
        {
          id: '0400018424269',
          brand: 'Meira T',
          name: '14K White Gold & 0.15 TCW Diamond Peace Necklace',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018424269',
          },
          price: '1190',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/meira-t-14k-white-gold--amp--0.15-tcw-diamond-peace-necklace-0400018424269.html',
        },
        {
          id: '0400015555154',
          brand: 'VALENTINO BY MARIO VALENTINO',
          name: 'Floral Logo-Adorned Leather Shoulder Bag',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400015555154',
          },
          price: '895',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/valentino-by-mario-valentino-floral-logo-adorned-leather-shoulder-bag-0400015555154.html',
        },
        {
          id: '0400018956266',
          brand: 'HONOR THE GIFT',
          name: "Littel Kid's & Kid's Car Pack Long Sleeve Sweatshirt",
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956266',
          },
          price: '32',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/honor-the-gift-littel-kid-s--amp--kid-s-car-pack-long-sleeve-sweatshirt-0400018956266.html',
        },
        {
          id: '0400018956238',
          brand: 'LOUIS VUITTON',
          name: 'Zippy Leather Wallet',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018956238',
          },
          price: '994.99',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/louis-vuitton-zippy-leather-wallet-0400018956238.html',
        },
        {
          id: '0400018944149',
          brand: 'BOBBI BROWN',
          name: 'Blush',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400018944149',
          },
          price: '35',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/bobbi-brown-blush-0400018944149.html',
        },
        {
          id: '0400014672817',
          brand: 'DOLCE&GABBANA',
          name: 'The One Eau de Toilette',
          image: {
            link: 'https://image.s5a.com/is/image/saksoff5th/0400014672817',
          },
          price: '134',
          quantity: 1,
          slugUrl:
            'https://www.saksoff5th.com/product/dolce-gabbana-the-one-eau-de-toilette-0400014672817.html',
        },
      ],
    },
  ],
};

const orderServiceMockData: { [propName: string]: IOrderResponse } = {
  MOCK_92612903029846543475000000: ORDER_ONE,
  MOCK_92612903029846543475000001: ORDER_ONE,
  MOCK_92612903029846543475000002: ORDER_TWO,
  MOCK_92612903029846543475000003: ORDER_TWO,
  MOCK_92612903029846543475000004: ORDER_THREE,
  MOCK_92612903029846543475000005: ORDER_THREE,
  MOCK_92612903029846543475000006: ORDER_FOUR,
  MOCK_92612903029846543475000007: ORDER_FOUR,
  MOCK_92612903029846543475000008: ORDER_FOUR,
  MOCK_92612903029846543475000009: ORDER_FOUR,
  MOCK_92612903029846543475000010: ORDER_FOUR,
  MOCK_92612903029846543475000011: ORDER_FOUR,
};

export const getOrderServiceMockDataKeys = () =>
  Object.keys(orderServiceMockData) as Array<keyof typeof orderServiceMockData>;

export default orderServiceMockData;
