import cx from 'classnames';
import React from 'react';
import usePropValidation from '@shared/utilities/hooks/usePropValidation';
import { requiredIconTextProps, schema } from '@shared/components/Hyperlink//IHyperlink';
import createAttributes, { hyperlinkType } from '@shared/components/Hyperlink/utilities/CreateAttributes';
import type { IHyperlinkProps } from '@shared/components/Hyperlink//IHyperlink';
import type { FC } from 'react';

export interface HyperlinkIconTextProps extends IHyperlinkProps {
  /** Is the part that will be visible to the reader. */
  label: string;
  /** Defines the icon component */
  icon: JSX.Element;
  /** Add classnames (from tailwind for example). You can add one or more in the same string */
  className?: string;
  /** Indicates the width of the Icon */
  width?: number;
  /** Indicates the height of the Icon */
  height?: number;
  /** Add classnames (from tailwind for example) for the svg. */
  svgClasses?: string;
  /** Reverse the order of the icon and text. */
  reverse?: boolean;
}

export const HyperlinkIconText: FC<HyperlinkIconTextProps> = (props) => {
  const shouldRender = usePropValidation(
    'HyperlinkIconText',
    props,
    requiredIconTextProps,
    schema,
  );
  const { label, icon, width, height, svgClasses } = props;
  const attributes = createAttributes(props, hyperlinkType.ICON_TEXT);
  if (!shouldRender) return null;

  const svgAttributes = {
    width,
    height,
    svgClasses: cx(svgClasses, 'hlink-svg'),
  };

  const iconComponent = React.isValidElement(icon)
    ? React.cloneElement(icon, { ...svgAttributes })
    : icon;

  return (
    <a {...attributes}>
      {iconComponent}
      <span className="hlink-label hlink-icon-text">{label}</span>
    </a>
  );
};

HyperlinkIconText.displayName = 'HyperlinkIconText';

export default HyperlinkIconText;
