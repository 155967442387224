export const STAGING_URLS = [
  'https://0sa7qbdwhd.execute-api.us-east-1.amazonaws.com/',
  'stage-tracking.saksoff5th.com/',
];

export const PRODUCTION_URLS = [
  'https://63f3imxj16.execute-api.us-east-1.amazonaws.com/',
  'tracking.saksoff5th.com/',
];

export default {
  STAGING_URLS,
  PRODUCTION_URLS,
};
