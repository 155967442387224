import { TrackingContext } from '@context/TrackingContext';
import Typography from '@typography/Typography';
import React from 'react';
import './Stepper.css';
import helpers from './helpers/stageHelpers';
import type { ITrackingStepperItem } from '@_types/type';

const Stepper = () => {
  const trackingResponse = React.useContext(TrackingContext)?.trackingResponse;
  const shipment = trackingResponse?.shipment;
  if (!(trackingResponse && shipment)) {
    return null;
  }

  const stages = helpers.generateStages(shipment.shipmentStatus);
  const currentStageIndex = helpers.getCurrentStageIndex(stages);
  const stageIcon = (stage: ITrackingStepperItem, stageIndex: number) =>
    helpers.getStageIcon({ stage, stageIndex, currentStageIndex });

  if (helpers.renderStages(stages, shipment) === false) {
    return null;
  }

  return (
    <div className="stepper-container">
      {stages.map((stage, stageIndex) => (
        <div
          key={stage.description}
          className={`icon-container ${helpers.getClassNameWhenStageActive(
            stage.active,
          )}`}
        >
          {stageIcon(stage, stageIndex)}
          <Typography
            className={`stage-description ${helpers.getFontWeight(
              stageIndex === currentStageIndex,
            )}`}
            fontFamily={helpers.getFontFamily(stageIndex === currentStageIndex)}
            component="span"
          >
            {stage.description}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
