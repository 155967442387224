import getOrder from '@services/orderService';
import isEmptyObject from '@utils/isEmptyObject';
import React, { createContext, useState, useEffect, useMemo } from 'react';
import type {
  IOrderContextProps,
  IOrderResponse,
  Nullable,
} from '@_types/type';

interface IOrderContextProviderProps {
  children: React.ReactNode;
  initialValue?: string | undefined;
}

export const OrderContext = createContext<Nullable<IOrderContextProps>>(null);

export const OrderContextProvider = ({
  children,
  initialValue,
}: React.PropsWithChildren<IOrderContextProviderProps>) => {
  const [orderResponse, setOrderResponse] =
    useState<Nullable<IOrderResponse>>(null);
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  useEffect(() => {
    const callAPI = async () => {
      try {
        const order = await getOrder(initialValue);
        if (!order || isEmptyObject(order)) {
          throw new Error('Order not found');
        }
        setOrderResponse(order);
      } catch {
        setOrderResponse(null);
      }
      setIsOrderLoading(false);
    };
    callAPI();
  }, [initialValue]);

  const contextValue = useMemo(
    () => ({
      orderResponse,
      isOrderLoading,
    }),
    [isOrderLoading, orderResponse],
  );

  return (
    <OrderContext.Provider value={contextValue}>
      {children}
    </OrderContext.Provider>
  );
};

OrderContextProvider.defaultProps = {
  initialValue: undefined,
};

export default { OrderContext, OrderContextProvider };
